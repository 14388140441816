import {uniq, random} from 'lodash';

class ZGEngineManager {
  constructor(vsRTCEngine) {
    this._vsRTCEngine = vsRTCEngine
    this._vsEngineHandler = null
    this._room = null
    this._appid = 4015100332
    this._appsign = "ea59ae967a6dd08e666c5cc6a8687dfb67f1544f34c7211dd7b09b867586a3bc"

    this._useVideo = true
    this._video_deviceid = ""
    this._audio_deviceid = ""
    this._audio_mode = "erasure"
    this._noise_suppression = false

    this._mystream = null
    this._bitrate = 512000
    this._resolution = "640*480"
    this._fps = 30
    this._streamId = ""

    this._othermystream = null
    this._otherstream_id = ""
    this._remotedOperator = []
    this._streams = {}
    this._feeds = {}


  }

  async _createStream(_streamingServer, _iceServers, room, push, pull) {
    this._room = room + ""
    this._vsEngineHandler = new ZegoExpressEngine(this._appid, "wss://webliveroom-test.zego.im/ws")
    this._vsEngineHandler.setDebugVerbose(true)
    //登录
    let login_room_rs = await this._vsEngineHandler.loginRoom(this._room, this._getZeGouToken(this._appid, this._appsign, this._vsRTCEngine._user_id), {
      userID: this._vsRTCEngine._user_id,
      userName: this._vsRTCEngine._user_id
    }, {
      userUpdate: true,
      maxMemberCount: 0
    })
    if (login_room_rs) {
      this.registerListener()
      this._vsRTCEngine._emitMediaFinish(1)
    } else {
      this.deregisterListener()
      this._vsRTCEngine._emitMediaFinish(0)
    }


  }

  registerListener() {
    /*
      房间状态更新回调
      房间状态分别会在断开连接，重试连接及重连成功时触发，state 分别为'DISCONNECTED'，'CONNECTING'，'CONNECTED'。
      DISCONNECTED：表示SDK已经尝试重试后，依旧不能在此连接服务器(默认尝试时间90s), 收到该值时，建议提示用户重试或者检测网络。
      CONNECTING：表示SDK检测到网络断开，可通过该值添加loading等待。
      CONNECTED：表示连接成功，关闭loading。
    */
    this._vsEngineHandler.on('roomStateUpdate', (roomID, state, errorCode, extendedData) => {
      // alert("roomStateUpdate:" + roomID + " " + state)
    })

    /*
      回调房间内其他用户的变化。
      roomID	房间 ID	字符串
      updateType	用户行为，DELETE 表示离开，ADD 表示进入	字符串
    */
    this._vsEngineHandler.on('roomUserUpdate', (roomID, updateType, userList) => {
      // alert("roomUserUpdate:" + roomID)
    })

    /*回调当前房间内的在线人数*/
    this._vsEngineHandler.on('roomOnlineUserCountUpdate', (roomID, count) => {
      // alert("roomOnlineUserCountUpdate:" + roomID)
    })

    /*订阅推流状态回调*/
    this._vsEngineHandler.on('publisherStateUpdate', (result) => {
      if (result.state == "PUBLISHING") {
        if (result.streamID.indexOf("-courseware-obj-") < 0) {
          this._vsRTCEngine._emitStartPush(this._vsRTCEngine._user_id, this._streamId)

        } else {
          this._vsRTCEngine._emitStartShare(this._vsRTCEngine._user_id, this._otherstream_id)
          this._vsRTCEngine._sharePending = false;
        }

      }
    })

    /*订阅拉流更新回调*/
    this._vsEngineHandler.on('roomStreamUpdate', (roomID, updateType, streamList) => {
      for (let o in streamList) {
        if (streamList[o].streamID.indexOf("-courseware-obj-") < 0) {
          if (updateType == "ADD") {
            this._feeds[streamList[o].user.userID] = streamList[o].streamID

          } else {
            if (this._feeds[streamList[o].user.userID]) delete this._feeds[streamList[o].user.userID]
          }
        } else {
          this._feeds[streamList[o].user.userID + "-courseware-obj-"] = streamList[o].streamID
        }

      }
      if (updateType == "ADD") {
        let update_streams = {}
        let share_ready_userID = ""
        for (let o in streamList) {
          if (streamList[o].streamID.indexOf("-courseware-obj-") < 0) {
            update_streams[streamList[o].user.userID] = streamList[o].streamID

          } else {
            update_streams[streamList[o].user.userID + "-courseware-obj-"] = streamList[o].streamID
          }
        }
        if (Object.keys(update_streams).length > 0) {
          this._vsRTCEngine._emitRemoteStreamReady(update_streams)

        }

      } else {
        for (let o in streamList) {
          if (streamList[o].streamID.indexOf("-courseware-obj-") < 0) {
            this._vsRTCEngine._emitStopPush(streamList[o].user.userID, streamList[o].streamID)

          } else {
            this._vsRTCEngine._emitStopShare(streamList[o].user.userID)

          }
        }
      }

    })

    /*订阅拉流状态回调*/
    this._vsEngineHandler.on('playerStateUpdate', (result) => {
      // alert("playerStateUpdate")
    })

    /*拉流摄像头状态回调*/
    this._vsEngineHandler.on('remoteCameraStatusUpdate', (streamID, status) => {
      // alert("remoteCameraStatusUpdate")
    })

    /*拉流麦克风状态回调*/
    this._vsEngineHandler.on('remoteMicStatusUpdate', (streamID, status) => {
      // alert("remoteMicStatusUpdate")
    })
  }

  deregisterListener() {
    this._vsEngineHandler.off('roomStateUpdate')
    this._vsEngineHandler.off('roomUserUpdate')
    this._vsEngineHandler.off('roomOnlineUserCountUpdate')
    this._vsEngineHandler.off('roomStreamUpdate')
    this._vsEngineHandler.off('playerStateUpdate')
    this._vsEngineHandler.off('remoteCameraStatusUpdate')
    this._vsEngineHandler.off('remoteMicStatusUpdate')
  }

  _destroyStream() {
    // this.deregisterListener()
    this._stopPushHandler()
    this._vsEngineHandler.logoutRoom(this._room)
    this._vsRTCEngine._emitMediaFinish(2)
  }

  async _pushHandler(userId, video) {
    //创建流
    if (video) {
      this._useVideo = video.video == false ? false : this._useVideo
      this._bitrate = video.bitrate || this._bitrate
      this._resolution = video.resolution || this._resolution
      this._video_deviceid = video.video_deviceid || this._video_deviceid
      this._audio_deviceid = video.audio_deviceid || this._audio_deviceid
      this._audio_mode = video.audio_mode || "erasure"
      this._noise_suppression = video.noise_suppression || this._noise_suppression
    } else {
      this._useVideo = false
    }
    if (userId) {
      this._userId = userId
    }

    let source = {
      camera: {
        audio: true,
        audioBitrate: "",
        video: this._useVideo,
        videoQuality: 4,
        width: parseInt(this._resolution.split("*")[0]),
        height: parseInt(this._resolution.split("*")[1]),
        frameRate: this._fps,
        bitrate: parseInt(this._bitrate) / 1000,
        ANS: this._audio_mode == "normal" ? false : true,
        AGC: this._audio_mode == "normal" ? false : true,
        AEC: this._audio_mode == "normal" ? false : true,
      }

    }
    if (this._audio_deviceid) {
      source["camera"]["audioInput"] = this._audio_deviceid
    }
    if (this._video_deviceid) {
      source["camera"]["videoInput"] = this._video_deviceid
    }
    this._mystream = await this._vsEngineHandler.createStream(source)
    this._vsRTCEngine._mycamera_stream = this._mystream

    this._streamId = (new Date().getTime()) + ""
    let start_publishing_stream = this._vsEngineHandler.startPublishingStream(this._streamId, this._mystream)

  }

  _stopPushHandler() {
    if (this._vsEngineHandler && this._mystream) {
      this._vsEngineHandler.stopPublishingStream(this._streamId)
      this._vsEngineHandler.destroyStream(this._mystream)
    }

    this._vsRTCEngine._mycamera_stream = null
    this._mystream = null
    this._streamId = ""

  }

  _pullHandler(userId) {
    //从ils里查询，有streamid的都需要通知
    let members = this._vsRTCEngine.getMembers().filter((v) => {
      return v.userId != this._vsRTCEngine._user_id && v.streamId && v.streamId != "0"
    })
    let update_streams = {}
    for (let o in members) {
      update_streams[members[o].userId] = members[o].streamId
      this._feeds[members[o].userId] = members[o].streamId
    }
    this._vsRTCEngine._emitRemoteStreamReady(update_streams)

  }

  _stopPullHandler() {


  }

  _registerUsername(sfutest, userId, bitrate) {

  }

  _getStream(userId, streamId) {
    var self = this
    var target = null
    for (let i = 0; i < self._remotedOperator.length; i++) {
      let remote = self._remotedOperator[i]
      if (streamId == remote.rfid || userId == remote.rfdisplay) {
        target = self._streams[remote.rfid]
        break
      }
    }

    return target
  }

  _getShareStream(userId, streamId) {
    userId = userId + "-courseware-obj-"
    var self = this
    var target = null
    for (let i = 0; i < self._remotedOperator.length; i++) {
      let remote = self._remotedOperator[i]
      if (streamId == remote.rfid) {
        target = self._streams[remote.rfid]
        break
      }
    }

    return target
  }

  async _republishOwnFeed(params, type) {
    var self = this
    var video = ""
    var useVideo = false
    if (params && typeof params.video == "object") {
      self._bitrate = params.video.bitrate || self._bitrate
      self._resolution = params.video.resolution || self._resolution
      self._video_deviceid = params.video.deviceId || self._video_deviceid

      video = self._resolution
      useVideo = true
    } else {
      video = self._resolution
      useVideo = params ? (params.video || self._useVideo) : self._useVideo
    }

    if (params && typeof params.audio == "object") {
      self._audio_deviceid = params.audio.deviceId || self._video_deviceid
    }

    this._vsEngineHandler.useVideoDevice(this._mystream, self._video_deviceid)
    this._vsEngineHandler.useAudioDevice(this._mystream, self._audio_deviceid)


    // self._publishOwnFeed(self._pushSfu, video, true, useVideo, self._bitrate, type)

  }

  _publishOwnFeed(sfutest, video, useAudio, useVideo, bitrate, replace) {

  }

  _publishOtherFeed(sfutest, video, useAudio, useVideo, bitrate, replace) {

  }

  async _newRemoteFeedAction(userId, video) {
    let stream_id = this._vsRTCEngine.getMembers([userId.split("-")[0]])
    let share_flag = false

    if (stream_id.length > 0) {
      if (userId.indexOf("-courseware-obj") < 0) {
        stream_id = stream_id[0].streamId
      } else {
        stream_id = stream_id[0].coursewareStreamId
        share_flag = true
      }
      let pull_stream = await this._vsEngineHandler.startPlayingStream(stream_id, {video: video})
      this._remotedOperator.push({rfindex: this._remotedOperator.length, rfid: stream_id, rfdisplay: userId})
      this._streams[stream_id] = pull_stream
      if (share_flag) {
        this._vsRTCEngine._emitStartShare(userId.split("-")[0])

      } else {
        this._vsRTCEngine._emitStartPull(userId)

      }
    }

  }

  _newRemoteFeed(userId, v) {
    var self = this;
    //保护一下，是否流ok
    var try_count = 10
    var try_interval = null
    if (!self._feeds[userId]) {
      try_interval = setInterval(() => {
        if (try_count >= 0 && self._feeds[userId]) {
          clearInterval(try_interval)
          self._newRemoteFeedAction(userId, v)

        } else {
          try_count--
          if (try_count <= 0) clearInterval(try_interval)
        }
      }, 2000)
    } else {
      self._newRemoteFeedAction(userId, v)
    }

  }

  _detachRemoteFeed(userId) {
    if (!userId) return
    let self = this
    let target = this._vsRTCEngine.getMembers([userId.split("-")[0]])
    let stream_id = ""
    if (target.length > 0) {
      if (userId.indexOf("-courseware-obj-") < 0) {
        stream_id = target[0].streamId

      } else {
        stream_id = target[0].coursewareStreamId

      }
    } else {
      return
    }
    if (userId) {
      this._vsEngineHandler.stopPlayingStream(stream_id)
      var leaveIndex = -1;
      var feed = this._remotedOperator.filter(function (v, k) {
        if (v.rfdisplay == userId) {
          leaveIndex = k;
          return true;
        } else {
          return false;
        }
      });

      if (feed.length > 0) {
        self._remotedOperator.splice(leaveIndex, 1);
        delete self._streams[feed[0].rfid];
      }

    } else {
      this._remotedOperator.forEach(function (v, k) {
        self._vsEngineHandler.stopPlayingStream(v.rfid)
        delete self._streams[v.rfid];
        v = null;

      });

      self._remotedOperator.splice(0);

    }

  }

  async _captureDeskTopScreen(options) {
    //创建分享流
    let source = {
      screen: {
        audio: false,
        videoQuality: 2
      }

    }

    this._othermystream = await this._vsEngineHandler.createStream(source)
    this._vsRTCEngine._mycourseware_stream = this._othermystream;

    this._otherstream_id = (new Date().getTime()) + "-courseware-obj-"
    let start_publishing_stream = this._vsEngineHandler.startPublishingStream(this._otherstream_id, this._othermystream)

  }

  _captureMedia(options, elm) {

  }

  _preShareScreen(sfutest, type) {

  }

  _shareScreen() {

  }

  _stopShare() {
    if (this._vsEngineHandler && this._othermystream) {
      this._vsEngineHandler.stopPublishingStream(this._otherstream_id)
      this._vsEngineHandler.destroyStream(this._othermystream)
    }

    this._vsRTCEngine._mycourseware_stream = null
    this._othermystream = null
    this._otherstream_id = ""
  }

  _blind(userId, status) {
    var videoStatus = status
    if (videoStatus == "on") {
      if (userId == this._userId) {
        this._vsEngineHandler.mutePublishStreamVideo(this._mystream, true)
      }
    } else {
      if (userId == this._userId) {
        this._vsEngineHandler.mutePublishStreamVideo(this._mystream, false)
      }
    }
  }

  _mute(userId, status) {
    var audioStatus = status;
    if (audioStatus == "on") {
      if (userId == this._userId) {
        this._vsEngineHandler.mutePublishStreamAudio(this._mystream, true)
      }

    } else {
      if (userId == this._userId) {
        this._vsEngineHandler.mutePublishStreamAudio(this._mystream, false)
      }

    }
  }

  enableReceiveRemoteVideo(userId, status) {

  }

  enableReceiveRemoteAudio(userId, status) {

  }

  _getZeGouToken(appId, appSign, userID) {

    let nonce = new Date().getTime().toString();
    let time = Math.floor(new Date().getTime() / 1000 + 30 * 60 * 30);
    let appSign32 = appSign.replace(/0x/g, '').replace(/,/g, '').substring(0, 32);
    console.log('appSign:' + time + '    ' + appSign32 + '    ' + nonce);

    if (appSign32.length < 32) {
      console.log('private sign erro!!!!');
      return null;
    }

    let sourece = md5(appId + appSign32 + userID + nonce + time);
    console.log('hash:' + sourece);


    let jsonStr = JSON.stringify({
      'ver': 1,
      'expired': time,
      'nonce': nonce,
      'hash': sourece
    });
    console.log('json', jsonStr);
    return Buffer.from(jsonStr).toString('base64');
  }

}

export default ZGEngineManager
