import {uniq, random} from 'lodash';

class VSEngineManager {
  constructor(vsRTCEngine) {
    this._vsRTCEngine = vsRTCEngine
    this._vsEngineHandler = null
    this._pushSfu = null
    this._pullSfu = null
    this._otherstreamSfu = null
    this._streamingServer = null
    this._iceServers = []
    this._room = null
    this._bitrate = 1200000
    this._resolution = "1280*720"
    this._fps = 25
    this._othermystreamuseaudio = false;
    this._othermystreamusebitrate = 1000000;
    this._sharefps = 60
    this._othermyid = "0";
    this._video_deviceid = ""
    this._audio_deviceid = ""
    this._audio_mode = "erasure"
    this._noise_suppression = false
    this._userId = null
    this._pushReadyTimer = null
    this._pullReadyTimer = null
    this._streamId = "0"
    this._ifStop = null
    this._ifStopPull = null
    this._ifPush = true
    this._ifPull = true
    this._myid = null
    this._mypvtid = null
    this._mystream = null
    this._main_stream_state = ""
    this._main_stream_count = 0
    this._othermystream = null
    this._useVideo = true
    this._feeds = {}
    this._remotedOperator = []
    this._streams = {}
    this.c_stream = null
    this._pull_stream_keepalive = null
    this._push_stream_keepalive = null
    this._push_stream_count = 0
    this.VS_STATE = {
      STARTING: "Starting",
      STARTED: "Started",
      RESTARTING: "ReStarting",
      FAILED: "Failed",
      IDEAL: "IDEAL",
    }

    this._cur_video_status = true
    this._cur_audio_status = true

    this._pullHandler_timer = null
    this._createStreaming = false
    this._pullHandler_status = false
    this._pushHandler_status = false

  }

  _createStream(_streamingServer, _iceServers, room, push, pull) {
    var self = this;
    if (self._createStreaming) {
      return
    }
    self._createStreaming = true
    self._streamingServer = _streamingServer
    self._iceServers = _iceServers || self._iceServers
    if (push === false) {
      this._ifPush = push
    } else {
      this._ifPush = true
    }
    if (pull === false) {
      this._ifPull = pull
    } else {
      this._ifPull = true
    }
    self._room = room
    if (self._vsEngineHandler && self._pushSfu) {
      self._vsEngineHandler.destroy();
      self._vsEngineHandler = null;
      self._pushSfu.detach();
      self._pushSfu = null;
    }
    this._stopPullHandler()
    this._stopPushHandler()
    this._stopShare()
    if (this._pull_stream_keepalive) {
      clearInterval(this._pull_stream_keepalive)
      this._pull_stream_keepalive = null
    }
    if (this._push_stream_keepalive) {
      clearInterval(this._push_stream_keepalive)
      this._push_stream_keepalive = null
    }
    this._main_stream_state = ""
    this._main_stream_count = 0
    this._feeds = {}
    VA.init({
      debug: "all", callback: function () {
        if (!VA.isWebrtcSupported()) {
          //通知外部浏览器不支持webrtc，请下载浏览器
          return;
        }
        // Create session
        var params = {
          server: self._streamingServer,
          success: function () {
            self._createStreaming = false
            // Attach to video room test plugin
            self._push_stream_keepalive = setInterval((v) => {
              if (self._main_stream_state && self._main_stream_state == self.VS_STATE.STARTING) {
                if (self._main_stream_count) {
                  self._main_stream_count++
                } else {
                  self._main_stream_count = 1
                }
                if (self._main_stream_count > 3) {
                  //累加器3次，不行直接累加置位0，然后重拉
                  self._main_stream_count = 0
                  self._pushHandler_status = false
                  self._pushHandler(self._userId, {video: self._useVideo})

                }
              }
              if (self._main_stream_state && self._main_stream_state == self.VS_STATE.STARTED) {
                //什么也不做
                if (self._main_stream_count) {
                  self._main_stream_count = 0
                }
              }
              if (self._main_stream_state && self._main_stream_state == self.VS_STATE.FAILED) {
                //状态置位starting，然后直接重拉
                self._main_stream_state = self.VS_STATE.RESTARTING
                if (self._main_stream_count) {
                  self._main_stream_count = 1
                }
                self._pushHandler_status = false
                self._pushHandler(self._userId, {video: self._useVideo})
              }
              if (self._main_stream_state && self._main_stream_state == self.VS_STATE.RESTARTING) {
                //累加器3次，不行直接累加置位0，然后重拉
                if (self._main_stream_count) {
                  self._main_stream_count++
                } else {
                  self._main_stream_count = 1
                }
                if (self._main_stream_count > 3) {
                  //累加器3次，不行直接累加置位0，然后重推
                  self._main_stream_count = 0
                  self._pushHandler_status = false
                  self._pushHandler(self._userId, {video: self._useVideo})
                }
              }
            }, 5000)
            self._pull_stream_keepalive = setInterval((v) => {
              for (let i in self._feeds) {
                if (i != self._userId && i != self._userId + "-courseware-obj-") {  //拉流的
                  let video = true
                  if (self._feeds[i].vs_video === false) {
                    video = false
                  } else if (!self._feeds[i].vs_video) {
                    video = true
                  }
                  if (self._feeds[i].vs_state && self._feeds[i].vs_state == self.VS_STATE.STARTING) {
                    if (self._feeds[i]["count"]) {
                      self._feeds[i]["count"]++
                    } else {
                      self._feeds[i]["count"] = 1
                    }
                    if (self._feeds[i]["count"] > 3) {
                      //累加器3次，不行直接累加置位0，然后重拉
                      self._feeds[i]["count"] = 0
                      if (i.indexOf("courseware-obj") >= 0) {
                        self._vsRTCEngine.startPullShare([i.split("-")[0]])

                      } else {

                        self._newRemoteFeed(i, video)

                      }
                    }
                  }
                  if (self._feeds[i].vs_state && self._feeds[i].vs_state == self.VS_STATE.STARTED) {
                    //什么也不做
                    if (self._feeds[i]["count"]) {
                      self._feeds[i]["count"] = 0
                    }
                  }
                  if (self._feeds[i].vs_state && self._feeds[i].vs_state == self.VS_STATE.FAILED) {
                    //状态置位starting，然后直接重拉
                    self._feeds[i].vs_state = self.VS_STATE.RESTARTING
                    if (self._feeds[i]["count"]) {
                      self._feeds[i]["count"] = 1
                    }

                    if (i.indexOf("courseware-obj") >= 0) {
                      self._vsRTCEngine.startPullShare([i.split("-")[0]])

                    } else {
                      self._newRemoteFeed(i, video, true)

                    }

                  }
                  if (self._feeds[i].vs_state && self._feeds[i].vs_state == self.VS_STATE.RESTARTING) {
                    //累加器3次，不行直接累加置位0，然后重拉
                    if (self._feeds[i]["count"]) {
                      self._feeds[i]["count"]++
                    } else {
                      self._feeds[i]["count"] = 1
                    }
                    if (self._feeds[i]["count"] > 3) {
                      //累加器3次，不行直接累加置位0，然后重拉
                      self._feeds[i]["count"] = 0

                      if (i.indexOf("courseware-obj") >= 0) {
                        self._vsRTCEngine.startPullShare([i.split("-")[0]])

                      } else {
                        self._newRemoteFeed(i, video, true)

                      }
                    }
                  }
                }
              }

            }, 15000)
            if (self._ifPush) {
              self._pushHandler(self._userId, {video: self._useVideo})
            }
            if (self._ifPull) {
              self._pullHandler(self._userId)

            }
            //向外通知engine句柄建立完毕
            self._push_stream_count = 0
            self._vsRTCEngine._emitMediaFinish(1)


          },
          error: function (error) {
            self._createStreaming = false
            VA.error(error);
            self._push_stream_count = 0
            self._vsRTCEngine._emitMediaFinish(0)
            //向外通知janus句柄断了，需要通知
          },
          destroyed: function () {
            self._createStreaming = false
            //window.close();
          }
        }
        if (_iceServers.length > 0) {
          params["iceServers"] = self._iceServers;
          params["iceTransportPolicy"] = "relay";
        }
        self._vsEngineHandler = new VA(params);
      }
    });
  }

  _destroyStream() {
    if (this._vsEngineHandler) {
      if (this._vsEngineHandler.destroy) this._vsEngineHandler.destroy();
      this._vsEngineHandler = null;

    }
    if (this._pushSfu) {
      if (this._pushSfu.detach) this._pushSfu.detach()
      this._pushSfutest = null
    }
    if (this._pullSfu) {
      if (this._pullSfu.detach) this._pullSfu.detach()
      this._pullSfu = null
    }
    if (this._otherstreamSfu) {
      if (this._otherstreamSfu.detach) this._otherstreamSfu.detach()
      this._pullSfu = null
    }
    if (this._push_stream_keepalive) {
      clearInterval(this._push_stream_keepalive)
      this._push_stream_keepalive = null
    }
    if (this._pull_stream_keepalive) {
      clearInterval(this._pull_stream_keepalive)
      this._pull_stream_keepalive = null
    }
    this._vsRTCEngine._emitMediaFinish(2)

  }

  _pushHandler(userId, video) {
    if (this._pushHandler_status) {
      return
    }
    this._stopPushHandler()
    this._pushHandler_status = true
    this._ifStop = false
    if (video) {
      this._useVideo = video.video == false ? false : this._useVideo
      this._bitrate = video.bitrate || this._bitrate
      this._fps = video.fps || this._fps
      this._resolution = video.resolution || this._resolution
      this._video_deviceid = video.video_deviceid || this._video_deviceid
      this._audio_deviceid = video.audio_deviceid || this._audio_deviceid
      this._audio_mode = video.audio_mode || "erasure"
      this._noise_suppression = video.noise_suppression || this._noise_suppression
    } else {
      this._useVideo = false
    }
    if (userId) {
      this._userId = userId
    }

    var self = this;
    if (self._pushSfu) {
      self._pushSfu.detach();
      self._pushSfu = null;
    }
    this._main_stream_state = this.VS_STATE.IDEAL
    self._vsEngineHandler.attach(
      {
        plugin: "janus.plugin.videoroom",
        opaqueId: "vsrtclib-" + VA.randomString(12),
        success: function (pluginHandle) {
          this._pushHandler_status = true
          self._pushSfu = pluginHandle;

          self._vsRTCEngine._quality_interval[self._userId] = setInterval(function () {
            if (self._pushSfu && self._pushSfu.webrtcStuff && self._pushSfu.webrtcStuff.mystates) {
              self._vsRTCEngine._quality_data.push({
                base: {
                  user_id: self._userId,
                  stream_id: self._streamId,
                  stream_tag: "camera",
                  timestamp: new Date().getTime()
                },
                data: self._pushSfu.webrtcStuff.mystates,
                feed: self._pushSfu,
              })
            }
          }, 10000)


          self._registerUsername(self._pushSfu, self._userId, self._bitrate);
        },
        error: function (error) {
          this._pushHandler_status = false
          //这里也可能通知推流失败了
          VA.error("  -- Error attaching plugin...", error);
          self._push_stream_count++
          if (self._push_stream_count >= 5) {
            self._vsRTCEngine._emitMediaFinish(0)
          } else {

            if (!self._pushSfu && !self._ifStop) {
              self._main_stream_state = self.VS_STATE.FAILED
              // self._pushHandler(self._userId, {video: self._useVideo})

            }

          }
          this._pushHandler_status = false
          self._vsRTCEngine._emitPubFailure()
        },
        consentDialog: function (on) {
          VA.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
        },
        iceState: function (on) {
          if (on == "disconnected") {
            self._pushReadyTimer = setTimeout(function () {
              //向外通知推流断了，要重连了
              self._pushHandler_status = false
              self._vsRTCEngine._emitPubFailure()
              self._main_stream_state = self.VS_STATE.FAILED
              // self._pushHandler(self._userId, {video: self._useVideo})
            }, 8000)

          } else if (on == "connected") {
            self._main_stream_state = self.VS_STATE.STARTED
            if (self._pushReadyTimer) clearTimeout(self._pushReadyTimer)
          }

        },
        mediaState: function (medium, on) {
          VA.log("VA " + (on ? "started" : "stopped") + " receiving our " + medium);
        },
        webrtcState: function (on) {
          VA.log("VA says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
          if (on) {
            self._streamId = self._myid
            //调用stream-update信令
            self._main_stream_state = self.VS_STATE.STARTED
            self._vsRTCEngine._emitStartPush(self._userId, self._streamId)
            // self._blind(self._userId)
            // self._mute(self._userId)

          } else {
            setTimeout(function () {
              if (!self._ifStop) {
                self._pushHandler_status = false
                self._main_stream_state = self.VS_STATE.FAILED
                // self._pushHandler(self._userId, {video: self._useVideo})

              }
            }, 1000)

          }

        },
        onmessage: function (msg, jsep) {
          VA.debug(" ::: Got a message (publisher) :::");
          VA.debug(msg);
          var event = msg["videoroom"];
          VA.debug("Event: " + event);
          if (event != undefined && event != null) {
            if (event === "joined") {
              self._myid = msg["id"];
              self._publishOwnFeed(self._pushSfu, self._resolution, true, self._useVideo, self._bitrate);


            } else if (event === "destroyed") {
              VA.warn("The room has been destroyed!");

            } else if (event === "event") {

              if (msg["publishers"] !== undefined && msg["publishers"] !== null) {

              } else if (msg["leaving"] !== undefined && msg["leaving"] !== null) {

              } else if (msg["unpublished"] !== undefined && msg["unpublished"] !== null) {

              } else if (msg["error"] !== undefined && msg["error"] !== null) {
                if (msg["error_code"] === 426) {
                  // This is a "no such room" error: give a more meaningful description

                } else if (msg["error_code"] === 428) {
                  console.log("终于出现428错误啦")
                }
              }
            }
          }
          if (jsep !== undefined && jsep !== null) {
            VA.debug("Handling SDP as well...");
            VA.debug(jsep);
            if (self._vsRTCEngine._director_turn && self._vsRTCEngine._director_turn.specialLine) {
              jsep.sdp = jsep.sdp.replace(new RegExp(self._vsRTCEngine._director_turn.vsf.publishIp, "gm"), self._vsRTCEngine._director_turn.vsf.privateIp)
              // jsep.sdp = jsep.sdp.replace(/59.110.243.24/g, '172.17.240.155')
            } else {
              if (self._vsRTCEngine._origin_ip && self._vsRTCEngine._override_ip) {
                jsep.sdp = jsep.sdp.replace(new RegExp(self._vsRTCEngine._origin_ip, "gm"), self._vsRTCEngine._override_ip)
              }
            }
            // jsep.sdp = self._updateBandwidthRestriction(jsep.sdp, self._bitrate);

            self._pushSfu.handleRemoteJsep({jsep: jsep});
          }
        },
        onlocalstream: function (stream) {
          VA.debug(" ::: Got a local stream :::");
          self._mystream = stream;
          self._vsRTCEngine._mycamera_stream = self._mystream
          VA.debug(stream);
          self._vsRTCEngine._addVolumeTimer(self._userId, self._mystream)

        },
        onremotestream: function (stream) {

        },
        oncleanup: function () {
          this._pushHandler_status = false
          VA.log(" ::: Got a cleanup notification: we are unpublished now :::");
          self._mystream = null;
        },
        detached: function () {
          this._pushHandler_status = false
        },
        ondataopen: function (data) {
          // alert("The DataChannel is available!");

        },
        ondata: function (data) {
          VA.debug("We got data from the DataChannel! " + data);

        }
      });
  }

  _stopPushHandler() {
    this._pushHandler_status = false
    var self = this;
    if (self._vsRTCEngine._quality_interval[self._userId]) {
      clearInterval(self._vsRTCEngine._quality_interval[self._userId])
      self._vsRTCEngine._quality_interval[self._userId] = null
    }
    if (self._pushSfu) {
      this._streamId = "0"
      this._ifStop = true
      self._pushSfu.detach();
      self._pushSfu = null;
      self._vsRTCEngine._removeVolumeTimer(self._userId)
    }


  }

  _pullHandler(userId) {
    if (this._pullHandler_status) {
      return
    }
    this._stopPullHandler()
    this._pullHandler_status = true
    var self = this;
    if (self._pullHandler_timer) clearTimeout(self._pullHandler_timer)
    this._ifStopPull = false
    if (userId) {
      this._userId = userId

    }
    self._feeds = {};
    self._remotedOperator.splice(0);
    self._vsEngineHandler.attach(
      {
        plugin: "janus.plugin.videoroom",
        opaqueId: "vsrtclib-" + VA.randomString(12),
        success: function (pluginHandle) {
          self._pullHandler_status = true
          self._pullSfu = pluginHandle;
          self._registerUsername(self._pullSfu, self._userId, self._bitrate);
        },
        error: function (error) {
          self._pullHandler_status = false
          VA.error("  -- Error attaching plugin...", error);
          self._pullHandler_timer = setTimeout(function () {
            self._pullHandler(self._userId)

          }, 2000)

        },
        consentDialog: function (on) {
          VA.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
        },
        mediaState: function (medium, on) {
          VA.log("VA " + (on ? "started" : "stopped") + " receiving our " + medium);
        },
        webrtcState: function (on) {
          VA.log("VA says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
          if (on) {

          } else {
            if (!self._ifStopPull) {
              self._pullHandler_timer = setTimeout(function () {
                self._pullHandler_status = false
                self._pullHandler(self._userId)

              }, 1000)

            }

          }

        },
        onmessage: function (msg, jsep) {
          VA.debug(" ::: Got a message (publisher) :::");
          VA.debug(msg);
          var event = msg["videoroom"];
          VA.debug("Event: " + event);
          if (event != undefined && event != null) {
            var now_feeds = {}
            if (event === "joined") {
              // self._myid = msg["id"];
              self._mypvtid = msg["private_id"];
              if (msg["publishers"] !== undefined && msg["publishers"] !== null) {
                var list = msg["publishers"];
                VA.debug("Got a list of available publishers/feeds:");
                VA.debug(list);
                for (var f in list) {
                  (function (ff, listt) {
                    var id = listt[ff]["id"];
                    var display = listt[ff]["display"];
                    var audio = listt[ff]["audio_codec"];
                    var video = listt[ff]["video_codec"];
                    VA.debug("  >> [" + id + "] " + display + " (audio: " + audio + ", video: " + video + ")");
                    now_feeds[display] = true
                    self._feeds[display] = {
                      id: id,
                      display: display,
                      audio: audio,
                      video: video,
                      vs_state: self.VS_STATE.IDEAL
                    }
                  })(f, list)

                }
                //对外通知哪些人的流已经ready
                self._vsRTCEngine._emitRemoteStreamReady(now_feeds)
              }

            } else if (event === "destroyed") {
              VA.warn("The room has been destroyed!");

            } else if (event === "event") {
              // Any new feed to attach to?
              if (msg["publishers"] !== undefined && msg["publishers"] !== null) {
                var list = msg["publishers"];
                VA.debug("Got a list of available publishers/feeds:");
                if (msg["publishers"] !== undefined && msg["publishers"] !== null) {
                  var list = msg["publishers"];
                  VA.debug("Got a list of available publishers/feeds:");
                  VA.debug(list);
                  for (var f in list) {
                    (function (ff, listt) {
                      var id = listt[ff]["id"];
                      var display = listt[ff]["display"];
                      var audio = listt[ff]["audio_codec"];
                      var video = listt[ff]["video_codec"];
                      VA.debug("  >> [" + id + "] " + display + " (audio: " + audio + ", video: " + video + ")");
                      now_feeds[display] = true
                      self._feeds[display] = {
                        id: id,
                        display: display,
                        audio: audio,
                        video: video,
                        vs_state: self.VS_STATE.IDEAL
                      }
                    })(f, list)
                  }
                }
                self._vsRTCEngine._emitRemoteStreamReady(now_feeds)
              } else if (msg["leaving"] !== undefined && msg["leaving"] !== null) {
                // One of the publishers has gone away?
                var leaving = msg["leaving"];
                VA.log("Publisher left: " + leaving);
                var leaveIndex = -1;
                var leaveTarget = null;
                if (self._othermyid == leaving) {
                  //向外通知自己分享结束了
                  self._othermyid = "0"
                  self._stopShare()
                  self._vsRTCEngine._emitStopShare(self._userId)
                } else {
                  self._remotedOperator.forEach(function (val, index) {
                    if (val.rfid == leaving) {
                      leaveIndex = index;
                      leaveTarget = val;
                    }
                  });
                  if (leaveIndex >= 0) {
                    if (leaveTarget.rfdisplay.indexOf("-courseware-obj-") < 0) {
                      //向外通知有流停止了
                      self._vsRTCEngine._emitStopPush(leaveTarget.rfdisplay, leaveTarget.rfid)

                    } else {
                      //向外通知别人分享结束了
                      self._vsRTCEngine._emitStopShare(leaveTarget.rfdisplay)

                    }
                    self._remotedOperator.splice(leaveIndex, 1);
                    delete self._streams[leaveTarget.rfid];
                    leaveTarget.detach()

                  }
                }


              } else if (msg["unpublished"] !== undefined && msg["unpublished"] !== null) {
                // One of the publishers has unpublished?
                var unpublished = msg["unpublished"];

                if (unpublished === 'ok') {
                  // That's us
                  self._pullSfu.hangup();
                  return;
                }
                VA.log("Publisher left: " + unpublished);
              } else if (msg["error"] !== undefined && msg["error"] !== null) {
                if (msg["error_code"] === 426) {
                  // This is a "no such room" error: give a more meaningful description

                } else if (msg["error_code"] === 428) {
                  console.log("终于出现428错误啦")
                }
              }
            }
          }

          if (jsep !== undefined && jsep !== null) {
            VA.debug("Handling SDP as well...");
            VA.debug(jsep);
            self._sfutest.handleRemoteJsep({jsep: jsep});
            // Check if any of the media we wanted to publish has
            // been rejected (e.g., wrong or unsupported codec)
          }
        },
        onlocalstream: function (stream) {
          VA.debug(" ::: Got a local stream :::");
          // self._mystream = stream;
          VA.debug(stream);

        },
        onremotestream: function (stream) {

        },
        oncleanup: function () {
          //如果是重连的情况，则需要重新去startlistener

          if (!this._ifStopPull) {
            setTimeout(function () {
              if (!self._pullSfu) {
                self._pullHandler_status = false
                self._pullHandler()
              }

            }, 2000)
          }
          VA.log(" ::: Got a cleanup notification: we are unpublished now :::");
          // self._mystream = null;
        },
        detached: function () {
          self._pullHandler_status = false
        }
      });
  }

  _stopPullHandler() {
    var self = this;
    self._pullHandler_status = false
    if (self._pullSfu) {
      this._ifStopPull = true
      self._detachRemoteFeed()
      self._pullSfu.detach();
      self._pullSfu = null;
    }


  }

  _registerUsername(sfutest, userId, bitrate) {
    var self = this;
    var register = {
      "request": "join",
      "room": self._room,   //TODO 需要配合RMS根据事件ID获取真实房间号
      "ptype": "publisher",
      "display": userId,
      "bitrate": bitrate ? parseInt(bitrate) : self._bitrate
    };
    sfutest.send({"message": register});
  }

  _getStream(userId, streamId) {
    var self = this
    var target = null
    for (let i = 0; i < self._remotedOperator.length; i++) {
      let remote = self._remotedOperator[i]
      if (streamId == remote.rfid || userId == remote.rfdisplay) {
        target = self._streams[remote.rfid]
        break
      }
    }

    return target
  }

  _getShareStream(userId, streamId) {
    userId = userId + "-courseware-obj-"
    var self = this
    var target = null
    for (let i = 0; i < self._remotedOperator.length; i++) {
      let remote = self._remotedOperator[i]
      if (streamId == remote.rfid || userId == remote.rfdisplay) {
        target = self._streams[remote.rfid]
        break
      }
    }

    return target
  }

  _republishOwnFeed(params, type) {
    var self = this
    var video = ""
    var useVideo = false

    if (params && typeof params.video == "object") {
      self._bitrate = params.video.bitrate || self._bitrate
      self._resolution = params.video.resolution || self._resolution
      self._video_deviceid = params.video.deviceId || self._video_deviceid

      video = self._resolution
      useVideo = self._useVideo
    } else {
      video = self._resolution
      useVideo = params ? (params.video != undefined ? params.video : self._useVideo) : self._useVideo
    }

    if (params && typeof params.audio == "object") {
      self._audio_deviceid = params.audio.deviceId || self._video_deviceid
    }


    self._publishOwnFeed(self._pushSfu, video, true, useVideo, self._bitrate, type)

  }

  _publishOwnFeed(sfutest, video, useAudio, useVideo, bitrate, replace) {
    var self = this;
    var w = 1280;
    var h = 720;
    var video_params = {}

    var seting = {
      audioRecv: false,
      videoRecv: false,
      audioSend: true,
      videoSend: useVideo,

    }
    if (replace) {
      seting["replaceVideo"] = useVideo
      seting["replaceAudio"] = true
    }

    if (video) {
      w = video.split("*")[0]
      h = video.split("*")[1]
      video_params["width"] = w
      video_params["height"] = h

    }


    if (this._video_deviceid) {
      seting["video"] = {deviceId: this._video_deviceid}
    }
    if (video) {
      if (seting["video"]) {
        seting["video"]["width"] = {ideal: parseInt(w)}
        seting["video"]["height"] = {ideal: parseInt(h)}
        seting["video"]["frameRate"] = {
          "min": this._fps,
          "max": this._fps
        }
      } else {
        seting["video"] = {
          width: {ideal: parseInt(w)},
          height: {ideal: parseInt(h)},
          frameRate: {
            "min": this._fps,
            "max": this._fps
          }
        }
      }
    }
    seting["audio"] = {}
    if (this._audio_deviceid) {
      seting["audio"]["deviceId"] = this._audio_deviceid

    }

    if (this._audio_mode && this._audio_mode == "normal") {
      seting["audio"]["latency"] = 0.002902
      seting["audio"]["noiseSuppression"] = false
      seting["audio"]["sampleSize"] = 16
      seting["audio"]["channelCount"] = 1  //不能有
      seting["audio"]["echoCancellation"] = false
      seting["audio"]["autoGainControl"] = false
      seting["audio"]["googTypingNoiseDetection"] = false
      sessionStorage["audio_mode"] = "normal"
    } else {
      sessionStorage["audio_mode"] = "erasure"
    }
    if (Object.keys(seting["audio"]).length == 0) {
      delete seting["audio"]
    }

    sfutest.createOffer(
      {
        iceRestart: false,
        media: seting,
        simulcast: false,
        success: function (jsep) {
          VA.debug("Got publisher SDP!");
          VA.debug(jsep);
          var publish = {
            "request": "configure",
            "audio": true,
            "video": useVideo,
            "bitrate": parseInt(bitrate) || 512000,
            "videocodec": "h264"
          };
          if (self._audio_mode == "normal") {
            jsep.sdp = jsep.sdp.replace("a=fmtp:111 minptime=10;useinbandfec=1", "a=fmtp:111 minptime=10; useinbandfec=1;sprop-stereo=1;stereo=1;maxaveragebitrate=128000")
            // jsep.sdp = jsep.sdp.replace("a=fmtp:111 minptime=10;useinbandfec=1", "a=fmtp:111 minptime=10; sprop-stereo=1;stereo=")
          }


          // var modifier = 'AS';
          // if (adapter.browserDetails.browser === 'firefox') {
          //   bandwidth = (bandwidth >>> 0) * 1000;
          //   modifier = 'TIAS';
          // }
          // if (sdp.indexOf('b=' + modifier + ':') === -1) {
          //   // insert b= after c= line.
          //   sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n');
          // } else {
          //   sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n');
          // }
          // return sdp;
          sfutest.send({"message": publish, "jsep": jsep});

          self._blind(self._userId)
          self._mute(self._userId)

        },
        error: function (error) {
          //向外通知pub_stream失败了
          // self._pullHandler()
        }
      });
  }

  _publishOtherFeed(sfutest, video, useAudio, useVideo, bitrate, replace) {
    var self = this;
    var w = 1280;
    var h = 720;
    var video_params = {}
    var seting = {
      audioRecv: false,
      videoRecv: false,
      audioSend: true,
      videoSend: useVideo,

    }
    if (replace) {
      seting["replaceVideo"] = true
    }

    if (video) {
      w = video.split("*")[0]
      h = video.split("*")[1]
      video_params["width"] = w
      video_params["height"] = h

    }


    if (this._video_deviceid) {
      seting["video"] = {deviceId: this._video_deviceid}
    }
    if (video) {
      if (seting["video"]) {
        seting["video"]["width"] = {ideal: parseInt(w)}
        seting["video"]["height"] = {ideal: parseInt(h)}
        seting["video"]["frameRate"] = {
          "min": this._fps,
          "max": this._fps
        }
      } else {
        seting["video"] = {
          width: {ideal: parseInt(w)},
          height: {ideal: parseInt(h)},
          frameRate: {
            "min": this._fps,
            "max": this._fps
          }
        }
      }
    }
    seting["audio"] = {}
    if (this._audio_deviceid) {
      seting["audio"]["deviceId"] = this._audio_deviceid

    }

    if (this._audio_mode && this._audio_mode == "normal") {
      seting["audio"]["latency"] = 0.002902
      seting["audio"]["noiseSuppression"] = true
      seting["audio"]["sampleSize"] = 16
      seting["audio"]["channelCount"] = 2
      seting["audio"]["echoCancellation"] = false
      seting["audio"]["autoGainControl"] = false
      seting["audio"]["googTypingNoiseDetection"] = false

      sessionStorage["audio_mode"] = "normal"
    } else {
      sessionStorage["audio_mode"] = "erasure"
    }
    if (Object.keys(seting["audio"]).length == 0) {
      delete seting["audio"]
    }

    sfutest.createOffer(
      {
        iceRestart: false,
        media: seting,
        simulcast: false,
        stream: this.c_stream,
        success: function (jsep) {
          VA.debug("Got publisher SDP!");
          VA.debug(jsep);
          var publish = {
            "request": "configure",
            "audio": true,
            "video": useVideo,
            "bitrate": parseInt(bitrate) || 512000,
            "videocodec": "h264"
          };
          if (this._audio_mode == "normal") {
            jsep.sdp = jsep.sdp.replace("a=fmtp:111 minptime=10;useinbandfec=1", "a=fmtp:111 minptime=10; useinbandfec=1;sprop-stereo=1;stereo=1;maxaveragebitrate=128000")
            // jsep.sdp = jsep.sdp.replace("a=fmtp:111 minptime=10;useinbandfec=1", "a=fmtp:111 minptime=10; sprop-stereo=1;stereo=")
          }

          sfutest.send({"message": publish, "jsep": jsep});
        },
        error: function (error) {
          //向外通知pub_stream失败了
          // self._pullHandler()
        }
      });
  }

  _newRemoteFeedAction(userId, v) {
    var remoteFeed = null;
    var self = this
    var id = self._feeds[userId].id
    var audio = self._feeds[userId].audio
    var video = self._feeds[userId].video
    var display = self._feeds[userId].display

    self._vsEngineHandler.attach(
      {
        plugin: "janus.plugin.videoroom",
        opaqueId: "vsrtclib-" + VA.randomString(12),
        success: function (pluginHandle) {
          remoteFeed = pluginHandle;
          remoteFeed.simulcastStarted = false;
          VA.log("Plugin attached! (" + remoteFeed.getPlugin() + ", id=" + remoteFeed.getId() + ")");
          VA.log("  -- This is a subscriber");
          // We wait for the plugin to send us an offer
          var listen = {
            "request": "join",
            "room": self._room,   //TODO 后续配合RMS
            "ptype": "subscriber",
            "feed": id,
            "private_id": self._mypvtid
          };
          VA.log(remoteFeed.getId());
          listen["offer_video"] = v ? v : false;
          remoteFeed.send({"message": listen});
        },
        error: function (error) {
          delete self._streams[id];
          if (remoteFeed && self._feeds[remoteFeed.rfdisplay] && self._feeds[remoteFeed.rfdisplay]["vs_state"] != self.VS_STATE.IDEAL) {
            delete self._streams[remoteFeed.rfid]
            self._feeds[remoteFeed.rfdisplay]["vs_state"] = self.VS_STATE.FAILED
            self._feeds[remoteFeed.rfdisplay]["vs_video"] = v
          }

          if (remoteFeed) remoteFeed.detach()
          remoteFeed = null
          // if (self._streams[id]) {
          //   //
          //
          // }

          VA.error("  -- Error attaching plugin...", error);
        },
        onmessage: function (msg, jsep) {
          VA.debug(" ::: Got a message (subscriber) :::");
          VA.debug(msg);
          var event = msg["videoroom"];
          VA.debug("Event: " + event);
          if (msg["error"] !== undefined && msg["error"] !== null) {
          } else if (event != undefined && event != null) {
            if (event === "attached") {
              // Subscriber created and attached
              remoteFeed.rfindex = self._remotedOperator.length;
              remoteFeed.rfid = msg["id"];
              remoteFeed.rfdisplay = msg["display"];
              var key = -1
              self._remotedOperator.forEach(function (val, k) {
                if (val.rfdisplay == remoteFeed.rfdisplay) {
                  key = k
                }
              })
              if (key >= 0) {
                self._remotedOperator[key] = remoteFeed
              } else {
                self._remotedOperator.push(remoteFeed);

              }

              VA.log("Successfully attached to feed " + remoteFeed.rfid + " (" + remoteFeed.rfdisplay + ") in room " + msg["room"]);

            } else if (event === "event") {
              // Check if we got an event on a simulcast-related event from this publisher

            } else {
              // What has just happened?
            }
          }
          if (jsep !== undefined && jsep !== null) {
            VA.debug("Handling SDP as well...");
            VA.debug(jsep);
            if (self._vsRTCEngine._director_turn && self._vsRTCEngine._director_turn.specialLine) {
              jsep.sdp = jsep.sdp.replace(new RegExp(self._vsRTCEngine._director_turn.vsf.publishIp, "gm"), self._vsRTCEngine._director_turn.vsf.privateIp)
              // jsep.sdp = jsep.sdp.replace(/59.110.243.24/g, '172.17.240.155')
            } else {
              if (self._vsRTCEngine._origin_ip && self._vsRTCEngine._override_ip) {
                jsep.sdp = jsep.sdp.replace(new RegExp(self._vsRTCEngine._origin_ip, "gm"), self._vsRTCEngine._override_ip)
              }
            }
            jsep.sdp = self._mutipleAudioSDPChange(jsep)
            // Answer and attach
            remoteFeed.createAnswer(
              {
                jsep: jsep,
                customizeSdp: function (jsep) {
                  jsep.sdp = self._mutipleAudioSDPChange(jsep)
                },

                // Add data:true here if you want to subscribe to datachannels as well
                // (obviously only works if the publisher offered them in the first place)
                media: {
                  audioSend: false,
                  videoSend: false,
                  videoRecv: true,
                  audioRecv: true
                },	// We want recvonly audio/video
                success: function (jsep) {
                  VA.debug("Got SDP!");
                  VA.debug(jsep);
                  var body = {"request": "start", "room": self.room};
                  // jsep.sdp = jsep.sdp.replace("a=fmtp:111 minptime=10;useinbandfec=1", "a=fmtp:111 minptime=10; useinbandfec=1;sprop-stereo=1;stereo=1")
                  // jsep.sdp=jsep.sdp+'b=as:128'


                  remoteFeed.send({"message": body, "jsep": jsep});
                },
                error: function (error) {
                  VA.error("WebRTC error:", error);
                }
              });
          }
        },
        iceState: function (on) {
          if (on == "disconnected") {
            self._pullReadyTimer = setTimeout(function () {
              //向外通知推流断了，要重连了
              if (remoteFeed && self._feeds[remoteFeed.rfdisplay] && self._feeds[remoteFeed.rfdisplay]["vs_state"] != self.VS_STATE.IDEAL) {
                if (self._feeds[remoteFeed.rfdisplay]["vs_state"] != self.VS_STATE.RESTARTING) {
                  delete self._streams[remoteFeed.rfid]

                  self._feeds[remoteFeed.rfdisplay]["vs_state"] = self.VS_STATE.FAILED
                  self._feeds[remoteFeed.rfdisplay]["vs_video"] = v
                  if (remoteFeed) remoteFeed.detach()
                  remoteFeed = null
                }


              }

            }, 8000)

          } else if (on == "connected") {
            self._feeds[remoteFeed.rfdisplay]["vs_state"] = self.VS_STATE.STARTED
            self._feeds[remoteFeed.rfdisplay]["vs_video"] = v
            if (self._pullReadyTimer) clearTimeout(self._pullReadyTimer)
          }

        },
        webrtcState: function (on) {    //此处需要加重拉机制
          if (!on) {
            if (remoteFeed && self._feeds[remoteFeed.rfdisplay] && self._feeds[remoteFeed.rfdisplay]["vs_state"] != self.VS_STATE.IDEAL) {
              if (self._feeds[remoteFeed.rfdisplay]["vs_state"] != self.VS_STATE.RESTARTING) {
                delete self._streams[remoteFeed.rfid]

                self._feeds[remoteFeed.rfdisplay]["vs_state"] = self.VS_STATE.FAILED
                self._feeds[remoteFeed.rfdisplay]["vs_video"] = v
              }

            }
            if (remoteFeed) remoteFeed.detach()
            remoteFeed = null

            // if (self._streams[id]) {
            //   self._newRemoteFeed(userId)
            //
            // }
          } else {
            self._feeds[remoteFeed.rfdisplay]["vs_state"] = self.VS_STATE.STARTED
            self._feeds[remoteFeed.rfdisplay]["vs_video"] = v
          }
          // VA.log("VA says this WebRTC PeerConnection (feed #" + remoteFeed.rfindex + ") is " + (on ? "up" : "down") + " now");
        },
        onlocalstream: function (stream) {
          // The subscriber stream is recvonly, we don't expect anything here
        },
        onremotestream: function (stream) {

          if (remoteFeed && !remoteFeed.rfdisplay) {
            return;
          }
          if (!self._streams[remoteFeed.rfid]) {
            self._streams[remoteFeed.rfid] = stream;
            self._vsRTCEngine._addVolumeTimer(remoteFeed.rfdisplay, stream)

            if (remoteFeed.rfdisplay.indexOf("-courseware-obj-") < 0) {
              //对外通知人流已经拉取成功
              self._vsRTCEngine._emitStartPull(remoteFeed.rfdisplay)
            } else {
              //对外通知分享流已经拉取成功
              self._vsRTCEngine._emitStartShare(remoteFeed.rfdisplay)
            }


            if (VA.webRTCAdapter.browserDetails.browser === "chrome" || VA.webRTCAdapter.browserDetails.browser === "firefox" ||
              VA.webRTCAdapter.browserDetails.browser === "safari") {
              if (self._vsRTCEngine._quality_interval[remoteFeed.rfdisplay]) {
                clearInterval(self._vsRTCEngine._quality_interval[remoteFeed.rfdisplay])
                self._vsRTCEngine._quality_interval[remoteFeed.rfdisplay] = null
              }
              self._vsRTCEngine._quality_interval[remoteFeed.rfdisplay] = setInterval(function () {
                if (remoteFeed && remoteFeed.webrtcStuff && remoteFeed.webrtcStuff.mystates) {
                  self._vsRTCEngine._quality_data.push({
                    base: {
                      user_id: remoteFeed.rfdisplay,
                      stream_id: remoteFeed.rfid,
                      stream_tag: "camera",
                      timestamp: new Date().getTime()
                    },
                    data: remoteFeed.webrtcStuff.mystates,
                    feed: remoteFeed,
                  })
                }
              }, 10000)
            }
          } else {
            self._streams[remoteFeed.rfid] = stream;
          }


        },
        oncleanup: function () {
          VA.log(" ::: Got a cleanup notification (remote feed " + id + ") :::");
        }
      });
  }

  _newRemoteFeed(userId, v, status) {
    var self = this;
    self._detachRemoteFeed(userId, status)

    //保护一下，是否流ok
    var try_count = 10
    var try_interval = null
    if (!self._feeds[userId]) {
      try_interval = setInterval(() => {
        if (try_count >= 0 && self._feeds[userId]) {
          clearInterval(try_interval)
          self._newRemoteFeedAction(userId, v)

        } else {
          try_count--
          if (try_count <= 0) clearInterval(try_interval)
        }
      }, 2000)
    } else {
      self._newRemoteFeedAction(userId, v)
    }

  }

  _detachRemoteFeed(userId, status) {
    var self = this;

    if (userId) {
      if (self._feeds[userId]) {
        self._feeds[userId]["vs_state"] = status ? self.VS_STATE.FAILED : self.VS_STATE.IDEAL
      }

      var leaveIndex = [];
      var feed = this._remotedOperator.filter(function (v, k) {
        if (v.rfdisplay == userId || v.rfdisplay.indexOf(userId) >= 0) {
          leaveIndex.push(k);
          return true;
        } else {
          return false;
        }
      });

      if (feed.length > 0) {
        feed.forEach((v) => {
          delete self._streams[v.rfid];
          v.detach();
          v.hangup(true);
          v = null;
        })

      }
      for (let i = leaveIndex.length - 1; i >= 0; i--) {
        leaveIndex.splice(leaveIndex[i], 1);
      }
      if (self._vsRTCEngine._quality_interval[userId]) {
        clearInterval(self._vsRTCEngine._quality_interval[userId])
        self._vsRTCEngine._quality_interval[userId] = null
      }
      self._vsRTCEngine._removeVolumeTimer(userId)
      self._vsRTCEngine._removeVolumeTimer(userId + "-courseware-obj-")


    } else {
      for (let i in self._feeds) {
        if (self._feeds[i]) {
          self._feeds[i]["vs_state"] = self.VS_STATE.IDEAL
          self._vsRTCEngine._removeVolumeTimer(i)
          self._vsRTCEngine._removeVolumeTimer(i + "-courseware-obj-")
          if (self._vsRTCEngine._quality_interval[i]) {
            clearInterval(self._vsRTCEngine._quality_interval[i])
            self._vsRTCEngine._quality_interval[i] = null
          }
        }
      }
      this._remotedOperator.forEach(function (v, k) {
        delete self._streams[v.rfid];
        v.detach();
        v.hangup(true);
        v = null;

      });
      self._remotedOperator.splice(0);

      // self._feeds = {}

    }

  }

  _captureDeskTopScreen(options) {
    var self = this;
    if (self._otherstreamSfu) {
      self._otherstreamSfu.detach()
      self._otherstreamSfu = null
    }
    if (options && options.useAudio) self._othermystreamuseaudio = options.useAudio;
    if (options && options.bitrate) self._othermystreamusebitrate = options.bitrate;
    self._vsEngineHandler.attach(
      {
        plugin: "janus.plugin.videoroom",
        opaqueId: "vsrtclib-" + VA.randomString(12),
        success: function (pluginHandle) {

          self._otherstreamSfu = pluginHandle;
          self._preShareScreen(self._otherstreamSfu, "screen")
        },
        error: function (error) {
          VA.error("  -- Error attaching plugin...", error);
          self._stopShare()
          window.postMessage({
            type: 'janusStopGetScreen'
          }, '*');
        },
        consentDialog: function (on) {
          VA.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
        },
        mediaState: function (medium, on) {
          VA.log("VA " + (on ? "started" : "stopped") + " receiving our " + medium);

          if (!on) {
            //向外通知停止分享了

          }
        },
        webrtcState: function (on) {
          VA.log("VA says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
          if (on) {
            self._vsRTCEngine._sharePending = false;
            if (self._vsRTCEngine._shareTimeoutTimer) clearTimeout(self._vsRTCEngine._shareTimeoutTimer)
            //对外通知更新分享流id，并通知分享成功
            self._vsRTCEngine._emitStartShare(self._userId, self._othermyid)
          } else {
            self._stopShare()
            window.postMessage({
              type: 'janusStopGetScreen'
            }, '*');
            self._vsRTCEngine._emitStopShare(self._userId)
            // self._captureDeskTopScreen()
          }

        },
        onmessage: function (msg, jsep) {
          VA.debug(" ::: Got a message (publisher) :::");
          VA.debug(msg);
          var event = msg["videoroom"];
          VA.debug("Event: " + event);
          if (event != undefined && event != null) {
            if (event === "joined") {
              self._othermyid = msg["id"];
              self._otherstreamSfu.createOffer(
                {
                  media: {
                    video: self._screen_capture,
                    audioSend: false,
                    audioRecv: false,
                    videoRecv: false,
                    screenshareFrameRate: self._sharefps
                  },	// Screen sharing Publishers are sendonly
                  success: function (jsep) {
                    VA.debug("Got publisher SDP!");
                    VA.debug(jsep);
                    var publish = {
                      "request": "configure",
                      "audio": false,
                      "video": true,
                    };
                    self._otherstreamSfu.send({
                      "message": publish,
                      "jsep": jsep,
                      "bitrate": self._othermystreamusebitrate
                    });
                  },
                  error: function (error) {
                    //向外通知停止分享了
                    self._stopShare()
                    window.postMessage({
                      type: 'janusStopGetScreen'
                    }, '*');
                    self._vsRTCEngine._emitStopShare(self._userId)
                    if (self._vsRTCEngine._shareTimeoutTimer) clearTimeout(self._vsRTCEngine._shareTimeoutTimer)
                    VA.error("WebRTC error:", error);
                    if(error.message.indexOf("Permission denied")>=0){
                      self._vsRTCEngine._noPerssionShare()

                    }
                  }
                }, "screen");
            }
          }
          if (jsep !== undefined && jsep !== null) {
            VA.debug("Handling SDP as well...");
            VA.debug(jsep);
            if (self._vsRTCEngine._director_turn && self._vsRTCEngine._director_turn.specialLine) {
              jsep.sdp = jsep.sdp.replace(new RegExp(self._vsRTCEngine._director_turn.vsf.publishIp, "gm"), self._vsRTCEngine._director_turn.vsf.privateIp)
              // jsep.sdp = jsep.sdp.replace(/59.110.243.24/g, '172.17.240.155')
            } else {
              if (self._vsRTCEngine._origin_ip && self._vsRTCEngine._override_ip) {
                jsep.sdp = jsep.sdp.replace(new RegExp(self._vsRTCEngine._origin_ip, "gm"), self._vsRTCEngine._override_ip)
              }
            }

            self._otherstreamSfu.handleRemoteJsep({jsep: jsep});
            // Check if any of the media we wanted to publish has
            // been rejected (e.g., wrong or unsupported codec)
          }
        },
        onlocalstream: function (stream) {
          VA.debug(" ::: Got a local stream :::");
          self._othermystream = stream;
          self._vsRTCEngine._mycourseware_stream = stream;

          self._vsRTCEngine._mycourseware_stream.addEventListener('inactive', function () {
            self._vsRTCEngine._mycourseware_stream.removeEventListener('inactive', function () {

            })
            self._stopShare()
            self._vsRTCEngine._emitStopShare(self._userId)
            window.postMessage({
              type: 'janusStopGetScreen'
            }, '*');
          }, false);

        },
        onremotestream: function (stream) {

        },
        oncleanup: function () {
          VA.log(" ::: Got a cleanup notification: we are unpublished now :::");
          // mystream = null;
        }
      });
  }

  _captureMedia(options, elm) {
    var v = document.getElementById(elm);
    this.c_stream = v.captureStream(20);
    var self = this;
    if (self._otherstreamSfu) {
      self._otherstreamSfu.detach()
      self._otherstreamSfu = null
    }
    if (options && options.useAudio) self._othermystreamuseaudio = options.useAudio;
    if (options && options.bitrate) self._othermystreamusebitrate = options.bitrate;
    self._vsEngineHandler.attach(
      {
        plugin: "janus.plugin.videoroom",
        opaqueId: "vsrtclib-" + VA.randomString(12),
        success: function (pluginHandle) {
          self._otherstreamSfu = pluginHandle;
          self._registerUsername(self._otherstreamSfu, self._userId + "-courseware-obj-", self._othermystreamusebitrate);
        },
        error: function (error) {
          VA.error("  -- Error attaching plugin...", error);
        },
        consentDialog: function (on) {
          VA.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
        },
        mediaState: function (medium, on) {
          VA.log("VA " + (on ? "started" : "stopped") + " receiving our " + medium);
          if (!on) {
            //向外通知停止分享了
            self._stopShare()

          }
        },
        webrtcState: function (on) {
          VA.log("VA says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
          if (on) {
            self._vsRTCEngine._sharePending = false;
            if (self._vsRTCEngine._shareTimeoutTimer) clearTimeout(self._vsRTCEngine._shareTimeoutTimer)
            //对外通知更新分享流id，并通知分享成功
            self._vsRTCEngine._emitStartShare(self._userId, self._othermyid)
          } else {
            self._stopShare()
            self._vsRTCEngine._emitStopShare(self._userId)
          }

        },
        onmessage: function (msg, jsep) {
          VA.debug(" ::: Got a message (publisher) :::");
          VA.debug(msg);
          var event = msg["videoroom"];
          VA.debug("Event: " + event);
          if (event != undefined && event != null) {
            if (event === "joined") {
              self._othermyid = msg["id"];
              self._publishOtherFeed(self._otherstreamSfu, "", true, true, self._othermystreamusebitrate);

            }
          }
          if (jsep !== undefined && jsep !== null) {
            VA.debug("Handling SDP as well...");
            VA.debug(jsep);
            self._otherstreamSfu.handleRemoteJsep({jsep: jsep});
            // Check if any of the media we wanted to publish has
            // been rejected (e.g., wrong or unsupported codec)
          }
        },
        onlocalstream: function (stream) {
          VA.debug(" ::: Got a local stream :::");
          self._othermystream = stream;
          self._vsRTCEngine._mycourseware_stream = stream;

        },
        onremotestream: function (stream) {

        },
        oncleanup: function () {
          VA.log(" ::: Got a cleanup notification: we are unpublished now :::");
          // mystream = null;
        },
        detached: function () {

        }
      });
  }

  _captureCanvas(options, elm) {
    var self = this;
    // var canvas = document.getElementById(elm)
    var canvas = document.getElementById("iframe").contentWindow.document.getElementById(elm)
    // var context = canvas.getContext("2d");
    this.c_stream = canvas.captureStream(10);
    this.c_stream.getVideoTracks()[0].requestFrame();

    if (self._otherstreamSfu) {
      self._otherstreamSfu.detach()
      self._otherstreamSfu = null
    }
    this._captureCanvasFrameTimer = null

    if (options && options.useAudio) self._othermystreamuseaudio = options.useAudio;
    if (options && options.bitrate) self._othermystreamusebitrate = options.bitrate;
    self._vsEngineHandler.attach(
      {
        plugin: "janus.plugin.videoroom",
        opaqueId: "vsrtclib-" + VA.randomString(12),
        success: function (pluginHandle) {
          self._otherstreamSfu = pluginHandle;
          self._registerUsername(self._otherstreamSfu, self._userId + "-courseware-obj-", self._othermystreamusebitrate);
        },
        error: function (error) {
          VA.error("  -- Error attaching plugin...", error);
        },
        consentDialog: function (on) {
          VA.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
        },
        mediaState: function (medium, on) {
          VA.log("VA " + (on ? "started" : "stopped") + " receiving our " + medium);

        },
        webrtcState: function (on) {
          VA.log("VA says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
          VA.log("VA says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
          if (on) {
            self._vsRTCEngine._sharePending = false;
            if (self._vsRTCEngine._shareTimeoutTimer) clearTimeout(self._vsRTCEngine._shareTimeoutTimer)
            //对外通知更新分享流id，并通知分享成功
            self._vsRTCEngine._emitStartShare(self._userId, self._othermyid)
          } else {
            self._stopShare()
            self._vsRTCEngine._emitStopShare(self._userId)
            // self._captureDeskTopScreen()
          }

        },
        onmessage: function (msg, jsep) {
          VA.debug(" ::: Got a message (publisher) :::");
          VA.debug(msg);
          VA.debug(" ::: Got a message (publisher) :::");
          VA.debug(msg);
          var event = msg["videoroom"];
          VA.debug("Event: " + event);
          if (event != undefined && event != null) {
            if (event === "joined") {
              self._othermyid = msg["id"];
              self._otherstreamSfu.createOffer(
                {
                  media: {
                    audioRecv: false,
                    videoRecv: false,
                    audioSend: false,
                    videoSend: true,
                    video: "stdres-16:9",
                  },
                  stream: self.c_stream,
                  success: function (jsep) {
                    VA.debug("Got publisher SDP!");
                    VA.debug(jsep);
                    var publish = {
                      "request": "configure",
                      "audio": false,
                      "video": true,
                      "bitrate": self._othermystreamusebitrate || 512000
                    };
                    // jsep.sdp = self._updateBandwidthRestriction(jsep.sdp, 64);
                    self._otherstreamSfu.send({"message": publish, "jsep": jsep});
                  },
                  error: function (error) {
                    //向外通知停止分享了
                    self._stopShare()
                    self._vsRTCEngine._emitStopShare(self._userId)
                    if (self._vsRTCEngine._shareTimeoutTimer) clearTimeout(self._vsRTCEngine._shareTimeoutTimer)
                    VA.error("WebRTC error:", error);
                  }
                });
            }
          }
          if (jsep !== undefined && jsep !== null) {
            VA.debug("Handling SDP as well...");
            VA.debug(jsep);
            if (self._vsRTCEngine._director_turn && self._vsRTCEngine._director_turn.specialLine) {
              jsep.sdp = jsep.sdp.replace(new RegExp(self._vsRTCEngine._director_turn.vsf.publishIp, "gm"), self._vsRTCEngine._director_turn.vsf.privateIp)
              // jsep.sdp = jsep.sdp.replace(/59.110.243.24/g, '172.17.240.155')
            } else {
              if (self._vsRTCEngine._origin_ip && self._vsRTCEngine._override_ip) {
                jsep.sdp = jsep.sdp.replace(new RegExp(self._vsRTCEngine._origin_ip, "gm"), self._vsRTCEngine._override_ip)
              }
            }
            self._otherstreamSfu.handleRemoteJsep({jsep: jsep});
            // Check if any of the media we wanted to publish has
            // been rejected (e.g., wrong or unsupported codec)
          }
        },
        onlocalstream: function (stream) {
          VA.debug(" ::: Got a local stream :::");
          self._othermystream = stream;
          self._vsRTCEngine._mycourseware_stream = stream;


        },
        onremotestream: function (stream) {

        },
        oncleanup: function () {
          VA.log(" ::: Got a cleanup notification: we are unpublished now :::");
          // mystream = null;
        },
        detached: function () {

        }
      });
  }

  _preShareScreen(sfutest, type) {
    var self = this;
    // Make sure HTTPS is being used
    // if (window.location.protocol !== 'https:') {
    //   //对外通知分享桌面只支持https
    //   return;
    // }
    if (!VA.isExtensionEnabled()) {
      //对外通知不支持分享桌面
      return;
    }
    self._screen_capture = type;
    if (navigator.mozGetUserMedia) {

    } else {
      self._shareScreen();
    }
  }

  _shareScreen() {
    var self = this;
    var create = {"request": "create", "description": "vs", "bitrate": 500000, "publishers": 1};
    self._otherstreamSfu.send({
      "message": create, success: function (result) {
        var event = result["videoroom"];
        VA.debug("Event: " + event);
        if (event != undefined && event != null) {
          var register = {
            "request": "join",
            "room": self._room,
            "ptype": "publisher",
            "display": self._userId + "-courseware-obj-"
          };
          self._otherstreamSfu.send({"message": register});
        }
      }
    });
  }

  _stopShare() {
    if (this._otherstreamSfu) {
      if (this._otherstreamSfu.detach) this._otherstreamSfu.detach()
      this._otherstreamSfu = null
    }
    if (this._captureCanvasFrameTimer) clearInterval(this._captureCanvasFrameTimer)
  }

  _blind(userId, status) {
    var videoStatus = status
    if (status) {
      if (videoStatus == "on") {
        if (userId == this._userId) {
          this._cur_video_status = false

          if (this._pushSfu && !this._pushSfu.isVideoMuted()) {
            this._pushSfu.muteVideo();

          }
        }
      } else {
        if (userId == this._userId) {
          this._cur_video_status = true

          if (this._pushSfu && this._pushSfu.isVideoMuted()) {
            this._pushSfu.unmuteVideo();

          }
        }
      }
    } else {
      if (!this._cur_video_status) {
        if (userId == this._userId) {

          if (this._pushSfu && !this._pushSfu.isVideoMuted()) {
            this._pushSfu.muteVideo();

          }
        }
      } else {
        if (userId == this._userId) {
          if (this._pushSfu && this._pushSfu.isVideoMuted()) {
            this._pushSfu.unmuteVideo();

          }
        }
      }
    }


  }

  _mute(userId, status) {

    var audioStatus = status;
    if (status) {
      if (audioStatus == "on") {

        if (userId == this._userId) {
          this._cur_audio_status = false
          if (this._pushSfu && !this._pushSfu.isAudioMuted()) {
            this._pushSfu.muteAudio();
          }
        }

      } else {
        if (userId == this._userId) {
          this._cur_audio_status = true
          if (this._pushSfu && this._pushSfu.isAudioMuted()) {
            this._pushSfu.unmuteAudio();
          }
        }

      }
    } else {
      if (!this._cur_audio_status) {
        if (userId == this._userId) {
          if (this._pushSfu && !this._pushSfu.isAudioMuted()) {
            this._pushSfu.muteAudio();
          }
        }

      } else {
        if (userId == this._userId) {
          if (this._pushSfu && this._pushSfu.isAudioMuted()) {
            this._pushSfu.unmuteAudio();
          }
        }

      }
    }

  }

  _mutipleAudioSDPChange(jsep) {
    // return jsep.sdp
    let new_sdps = []
    let sdps = jsep.sdp.split("\r\n")
    let fmtp_idx = -1
    let opus_idx = -1
    let id = ""
    let exist_audio = false
    sdps.forEach(function (v, k) {
      if (v.indexOf("opus/48000/2") >= 0) {
        opus_idx = k
        id = v.split(" ")[0].split(":")[1]
        let fmtp = sdps.filter(function (v1, k1) {
          if (v1.indexOf("a=fmtp:" + id) >= 0) {
            fmtp_idx = k1
            return true
          } else {
            return false
          }

        })
      }
      if (v.indexOf("a=mid:audio") >= 0) {
        exist_audio = true
      }
    })
    if (fmtp_idx >= 0) {
      sdps[fmtp_idx] += ";stereo=1"
      new_sdps = sdps
    } else {
      for (var i = 0; i <= opus_idx; i++) {
        new_sdps.push(sdps[i])
      }
      if (exist_audio) {
        new_sdps.push("a=fmtp:" + id + " stereo=1")
      }
      for (var i = opus_idx + 1; i < sdps.length; i++) {
        new_sdps.push(sdps[i])
      }
    }
    jsep.sdp = new_sdps.join("\r\n")
    return jsep.sdp
  }

  enableReceiveRemoteVideo(userId, status) {
    var self = this;
    var target = this._remotedOperator.filter(function (v, k) {
      return v.rfdisplay == userId;
    });
    if (target.length == 0) {
      target = this._remotedOperator.filter(function (v, k) {
        return v.rfdisplay.indexOf(userId) >= 0;
      });
    }
    if (target.length > 0) {
      target.forEach((remoteFeed) => {
        var config = remoteFeed.webrtcStuff;
        if (config.pc === null || config.pc === undefined) {
          return false;
        }
        if (config.remoteStream === undefined || config.remoteStream === null) {
          return false;
        }
        if (config.remoteStream.getVideoTracks() === null
          || config.remoteStream.getVideoTracks() === undefined
          || config.remoteStream.getVideoTracks().length === 0) {
          return false;
        }
        config.remoteStream.getVideoTracks()[0].enabled = status;
      })

      return true;
    }
  }

  enableReceiveRemoteAudio(userId, status) {
    var self = this;
    var target = this._remotedOperator.filter(function (v, k) {
      // return v.rfdisplay == userId || v.rfdisplay.indexOf(userId) >= 0;
      return v.rfdisplay == userId;
    });
    if (target.length == 0) {
      target = this._remotedOperator.filter(function (v, k) {
        return v.rfdisplay.indexOf(userId) >= 0;
      });
    }
    if (target.length > 0) {
      target.forEach((remoteFeed) => {
        // var remoteFeed = target[0];
        var config = remoteFeed.webrtcStuff;
        if (config.pc === null || config.pc === undefined) {
          return false;
        }
        if (config.remoteStream === undefined || config.remoteStream === null) {
          return false;
        }
        if (config.remoteStream.getAudioTracks() === null
          || config.remoteStream.getAudioTracks() === undefined
          || config.remoteStream.getAudioTracks().length === 0) {
          return false;
        }
        config.remoteStream.getAudioTracks()[0].enabled = status;
      })

      return true;

    }
  }

}

export default VSEngineManager
