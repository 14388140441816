import {uniq, random} from 'lodash';

class TXEngineManager {
  constructor(vsRTCEngine) {
    this._vsRTCEngine = vsRTCEngine
    this._vsEngineHandler = null
    this._room = null
    this._appid = 4015100332
    this._appsign = "ea59ae967a6dd08e666c5cc6a8687dfb67f1544f34c7211dd7b09b867586a3bc"

    this._useVideo = true
    this._video_deviceid = ""
    this._audio_deviceid = ""
    this._audio_mode = "erasure"
    this._noise_suppression = false

    this._mystream = null
    this._bitrate = 512000
    this._resolution = "640*480"
    this._fps = 30
    this._streamId = ""

    this._othermystream = null
    this._otherstream_id = ""
    this._remotedOperator = []
    this._streams = {}
    this._feeds = {}

    this._firstMediaFrame = false
    this._video_dom_id = ""
    this._video_push_url = ""


  }

  _init(push_url, video_dom_id) {
    if (!this._vsEngineHandler) {
      this._video_dom_id = video_dom_id
      this._video_push_url = push_url
      this._vsEngineHandler = new TXLivePusher();
      this._vsEngineHandler.setRenderView(video_dom_id);
      this._vsEngineHandler.setProperty("setConnectRetryCount", 10)
      if (this._vsRTCEngine._resolution) {
        this._vsEngineHandler.setProperty("setVideoResolution", {
          "width": parseInt(this._vsRTCEngine._resolution.split("*")[0]),
          "height": parseInt(this._vsRTCEngine._resolution.split("*")[1])
        })
      }
      if (this._vsRTCEngine._fps) {
        this._vsEngineHandler.setProperty("setVideoFPS", this._vsRTCEngine._fps)
      }
      if (this._vsRTCEngine._bitrate) {
        this._vsEngineHandler.setProperty("setVideoBitrate", parseInt(parseInt(this._vsRTCEngine._bitrate) / 1000))
      }
      document.getElementById(this._video_dom_id).getElementsByTagName('video')[0].muted = true;
      this.registerListener()
    }


  }

  async _createStream(_streamingServer, _iceServers, room, push, pull) {
    //登录
    // this._vsEngineHandler.startCamera();
    // this._vsEngineHandler.startMicrophone();
    this._init(this._video_push_url, this._video_dom_id)
    this._vsRTCEngine._emitMediaFinish(1)


  }

  registerListener() {
    var self = this
    var hasVideo = false;
    var hasAudio = false;
    var isPush = false;
    this._vsEngineHandler.setObserver({
      onCaptureFirstAudioFrame: function () {
        console.log("===onCaptureFirstAudioFrame===")
        hasAudio = true;
        if (hasVideo && !isPush) {
          isPush = true;
          self._firstMediaFrame = true

        }
      },
      onCaptureFirstVideoFrame: function () {
        console.log("===onCaptureFirstVideoFrame===")
        hasVideo = true;
        if (hasAudio && !isPush) {
          isPush = true;
          self._firstMediaFrame = true
        }
      },
      onPushStatusUpdate: function (status, msg, extraInfo) {
        console.log("===onPushStatusUpdate===")
        console.log(status, msg, extraInfo)
      },
      onStatisticsUpdate: function (statistics) {
        console.log("===onStatisticsUpdate===")
        console.log(statistics)
      },
      onError: function (code, msg, extraInfo) {
        console.log("===onError===")
        console.log(code, msg, extraInfo)
      },
      onWarning: function (code, msg, extraInfo) {
        console.log("===onWarning===")
        console.log(code, msg, extraInfo)
      }
    });
  }

  _startPreview() {
    if (this._vsEngineHandler) {
      this._vsEngineHandler.startCamera()
      this._vsEngineHandler.startMicrophone()
    }

  }

  _stopPreview() {
    if (this._vsEngineHandler) {
      this._vsEngineHandler.stopCamera()
      this._vsEngineHandler.stopMicrophone()
    }

  }

  deregisterListener() {

  }

  _destroyStream() {
    if (this._vsEngineHandler) {
      this._vsEngineHandler.stopPush();
      this._vsEngineHandler.destroy();
      this._vsEngineHandler = null;
    }

    this._vsRTCEngine._emitMediaFinish(2)
  }

  _pushHandler(userId, video) {
    this._vsEngineHandler.startPush(this._video_push_url);
    this._vsRTCEngine._emitStartPush(userId, "txstreamid")

  }

  _stopPushHandler() {
    this._vsEngineHandler.stopPush();

  }

  _pullHandler(userId) {


  }

  _stopPullHandler() {


  }

  _registerUsername(sfutest, userId, bitrate) {

  }

  _getStream(userId, streamId) {

  }

  _getShareStream(userId, streamId) {

  }

  async _republishOwnFeed(params, type) {
    var self = this
    var deviceManager = this._vsEngineHandler.getDeviceManager();
    if (params.video) {
      self._vsRTCEngine._useVideo = params.video.deviceId
    }
    if (params.video && params.video.deviceId) {
      self._vsRTCEngine._video_deviceid = params.video.deviceId
      deviceManager.switchCamera(params.video.deviceId);
    }
    if (params.audio && params.audio.deviceId) {
      self._vsRTCEngine._video_deviceid = params.audio.deviceId
      deviceManager.switchMicrophone(params.audio.deviceId);
    }
    if (params.video && (params.video.resolution || params.video.bitrate)) {
      this._stopPushHandler()
      this._stopPreview()
      setTimeout(function () {
        if (params.video.resolution) {
          self._vsRTCEngine._resolution = params.resolution
          self._vsEngineHandler.setProperty("setVideoResolution", {
            width: parseInt(params.video.resolution.split("*")[0]),
            height: parseInt(params.video.resolution.split("*")[1])
          })
        }

        if (params.video.bitrate) {
          self._vsRTCEngine._bitrate = params.bitrate
          self._vsEngineHandler.setProperty("setVideoBitrate", parseInt(parseInt(params.video.bitrate) / 1000))
        }

        self._vsRTCEngine.startPreview("", function () {
          self._vsRTCEngine.attachLocalMedia()
          setTimeout(function () {
            self._pushHandler()

          }, 2000)

        }, 1000)

      }, 1000)

    }

  }

  _publishOwnFeed(sfutest, video, useAudio, useVideo, bitrate, replace) {

  }

  _publishOtherFeed(sfutest, video, useAudio, useVideo, bitrate, replace) {

  }

  async _newRemoteFeedAction(userId, video) {

  }

  _newRemoteFeed(userId, v) {


  }

  _detachRemoteFeed(userId) {

  }

  async _captureDeskTopScreen(options) {

  }

  _captureMedia(options, elm) {

  }

  _preShareScreen(sfutest, type) {

  }

  _shareScreen() {

  }

  _stopShare() {

  }

  _blind(userId, status) {
    var videoStatus = status
    if (videoStatus == "on") {
      this._vsEngineHandler.setVideoMute(true);
    } else {
      this._vsEngineHandler.setVideoMute(false);
    }
  }

  _mute(userId, status) {
    var videoStatus = status
    if (videoStatus == "on") {
      this._vsEngineHandler.setAudioMute(true)
    } else {
      this._vsEngineHandler.setAudioMute(false)
    }
  }

  enableReceiveRemoteVideo(userId, status) {

  }

  enableReceiveRemoteAudio(userId, status) {

  }

  _getZeGouToken(appId, appSign, userID) {

  }

}

export default TXEngineManager
