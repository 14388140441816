class ListenerManager {
  constructor(listener_handler) {
    this.listener_handler = listener_handler
  }

  on(eventName, callback) {
    if (!this.handles) {
      this.handles = {};
    }
    if (!this.handles[eventName]) {
      this.handles[eventName] = [];
    }
    this.handles[eventName].push(callback);
  }

  emit(eventName) {
    if (this.handles[arguments[0]]) {
      for (var i = 0; i < this.handles[arguments[0]].length; i++) {
        this.handles[arguments[0]][i](arguments[1]);
      }
    }
  }

   _emitListener(type, message, user_id, data) {
    let param = {...message}
    if (user_id) {
      param["userId"] = user_id
    }
    if (data) {
      param["data"] = data
    }
    switch (type) {
      case ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_SYSTEM_CONNECT, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_NO_SCREEN_SHARING_EXTENSION:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.NO_SCREEN_SHARING_EXTENSION, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_JOIN:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_JOIN, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_LEAVE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_LEAVE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_KICKOUT:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_KICKOUT, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_ROOM_INFO_UPDATE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_ROOM_INFO_UPDATE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_CUSTOMPARAMS_UPDATE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_CUSTOMPARAMS_UPDATE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_PUB_LOST:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_PUB_LOST, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_REMOTE_VIDEO_READY:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_REMOTE_VIDEO_READY, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_BLIND:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_BLIND, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_MUTE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_MUTE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_SHARE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_SHARE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_QUALITY_NOTIFY:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_QUALITY_NOTIFY, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_DISPATCH_MESSAGE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_DISPATCH_MESSAGE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_MEMBERLIST_UPDATE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_MEMBERLIST_UPDATE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_START_PUSH:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_START_PUSH, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_STOP_PUSH:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_STOP_PUSH, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_START_SHARE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_START_SHARE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_STOP_SHARE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_STOP_SHARE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_MEDIA_HANDLER_ERROR:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_MEDIA_HANDLER_ERROR, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_CAN_PULL_STREAM:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_CAN_PULL_STREAM, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_CAN_PULL_SHARE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_CAN_PULL_SHARE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_START_PULL:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_START_PULL, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_STOP_PULL:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_STOP_PULL, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_VOLUME_CHANGE:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_VOLUME_CHANGE, param)
        break
      case ListenerManager.COMMON_LISTENER.COMMON_ON_UI_DATA:
        this.listener_handler.emit(ListenerManager.ON_LISTENER.ON_UI_DATA, param)
        break

    }
  }


  registerCommonListener() {
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_NO_SCREEN_SHARING_EXTENSION, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_JOIN, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_LEAVE, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_KICKOUT, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_ROOM_INFO_UPDATE, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_CUSTOMPARAMS_UPDATE, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_PUB_LOST, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_START_PUSH, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_STOP_PUSH, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_REMOTE_VIDEO_READY, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_BLIND, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_MUTE, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_VOLUME_CHANGE, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_SHARE, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_QUALITY_NOTIFY, function (data) {

    })
    this.on(ListenerManager.COMMON_LISTENER.COMMON_ON_DISPATCH_MESSAGE, function (data) {

    })

  }

  registerVSListener() {

  }

  registerZGListener() {

  }
}

ListenerManager.COMMON_LISTENER = {
  COMMON_ON_SYSTEM_CONNECT: "common-on-system-connect",
  COMMON_NO_SCREEN_SHARING_EXTENSION: "common-no-screen-sharing-perssion",
  COMMON_ON_JOIN: "common-on-join",
  COMMON_ON_LEAVE: "common-on-leave",
  COMMON_ON_KICKOUT: "common-on-kickout",
  COMMON_ON_ROOM_INFO_UPDATE: "common-on-room-info-update",
  COMMON_ON_CUSTOMPARAMS_UPDATE: "common-on-customParams-update",
  COMMON_ON_PUB_LOST: "common-on-pub-lost",
  COMMON_ON_REMOTE_VIDEO_READY: "common-on-remote-video-ready",
  COMMON_ON_BLIND: "common-on-blind",
  COMMON_ON_MUTE: "common-on-mute",
  COMMON_ON_SHARE: "common-on-share",
  COMMON_ON_QUALITY_NOTIFY: "common-on-quality-notify",
  COMMON_ON_DISPATCH_MESSAGE: "common-on-dispatch-message",
  COMMON_ON_MEMBERLIST_UPDATE: "common-on-memberlist-update",
  COMMON_ON_START_PUSH: "common-on-start-push",
  COMMON_ON_STOP_PUSH: "common-on-stop-push",
  COMMON_ON_START_SHARE: "common-on-start-share",
  COMMON_ON_STOP_SHARE: "common-on-stop-share",
  COMMON_ON_MEDIA_HANDLER_ERROR: "common-on-media-handler-error",
  COMMON_ON_CAN_PULL_STREAM: "common-on-can-pull-stream",
  COMMON_ON_CAN_PULL_SHARE: "common-on-can-pull-share",
  COMMON_ON_START_PULL: "common-on-start-pull",
  COMMON_ON_STOP_PULL: "common-on-stop-pull",
  COMMON_ON_VOLUME_CHANGE: "common-on-volume-change",
  COMMON_ON_UI_DATA: "common-on-ui-data",
}

ListenerManager.ON_LISTENER = {
  ON_SYSTEM_CONNECT: "on-system-connect",
  NO_SCREEN_SHARING_EXTENSION: "no-screen-sharing-extension",
  ON_JOIN: "on-join",
  ON_LEAVE: "on-leave",
  ON_KICKOUT: "on-kickout",
  ON_ROOM_INFO_UPDATE: "on-room-info-update",
  ON_CUSTOMPARAMS_UPDATE: "on-customParams-update",
  ON_PUB_LOST: "on-pub-lost",
  ON_REMOTE_VIDEO_READY: "on-remote-video-ready",
  ON_BLIND: "on-blind",
  ON_MUTE: "on-mute",
  ON_SHARE: "on-share",
  ON_QUALITY_NOTIFY: "on-quality-notify",
  ON_DISPATCH_MESSAGE: "on-dispatch-message",
  ON_MEMBERLIST_UPDATE: "on-memberlist-update",
  ON_START_PUSH: "on-start-push",
  ON_STOP_PUSH: "on-stop-push",
  ON_START_SHARE: "on-start-share",
  ON_STOP_SHARE: "on-stop-share",
  ON_MEDIA_HANDLER_ERROR: "on-media-handler-ERROR",
  ON_CAN_PULL_STREAM: "on-can-pull-stream",
  ON_CAN_PULL_SHARE: "on-can-pull-share",
  ON_START_PULL: "on-start-pull",
  ON_STOP_PULL: "on-stop-pull",
  ON_VOLUME_CHANGE: "on-volume-change",
  ON_UI_DATA: "on-ui-data",
}

ListenerManager.COMMON_MESSAGE = {
  ILS_TOKEN_FAILURE: {
    status: false,
    code: 10001,
    message: "The signaling server failed to get the token."
  },
  VRC_SERVER_CONNECT_FAILURE: {
    status: false,
    code: 10002,
    message: "The event server connection failed."
  },
  EVENT_INVALID: {
    status: false,
    code: 10003,
    message: "Illegal event request."
  },
  NO_AVAILABLE_ROOM: {
    status: false,
    code: 10004,
    message: "No room resources available."
  },
  JOINING_ROOM: {
    status: true,
    code: 10005,
    message: "Joining room."
  },
  JOINED_ROOM: {
    status: true,
    code: 10006,
    message: "Joined room."
  },
  SESSION_END: {
    status: true,
    code: 10008,
    message: "End of the session."
  },
  LEAVED_ROOM: {
    status: true,
    code: 10009,
    message: "User leaved successfully.",
  },
  USER_CUSTOMPARAMS_CHANGE: {
    status: true,
    code: 10010,
    message: "User custom parameters have changed."
  },
  BLIND_CHANGE: {
    status: true,
    code: 10011,
    message: "The user's camera status has changed."
  },
  MUTED_CHANGE: {
    status: true,
    code: 10012,
    message: "The user's microphone status has changed."
  },
  VRC_TOKEN_FAILURE: {
    status: false,
    code: 10013,
    message: "The event server failed to get the token."
  },
  DISCONNECTED_SERVER: {
    status: true,
    code: 10014,
    message: "Disconnected server."
  },
  CONNECTED_SERVER: {
    status: true,
    code: 10015,
    message: "Connected server."
  },
  CONNECTING_SERVER: {
    status: true,
    code: 10016,
    message: "Connecting server."
  },
  MEMBER_LIST_UPDATE: {
    status: true,
    code: 10017,
    message: "Members list updated."
  },
  NO_AVAILABLE_DEVICES: {
    status: false,
    code: 10018,
    message: "No available devices."
  },
  PERMISSION_DENY_DEVICES: {
    status: false,
    code: 10019,
    message: "Permission denied to use your device."
  },
  START_PUSH: {
    status: true,
    code: 10020,
    message: "Start push camera stream."
  },
  STOP_PUSH: {
    status: true,
    code: 10021,
    message: "Stop push camera stream."
  },
  START_SHARE: {
    status: true,
    code: 10022,
    message: "Start share stream."
  },
  STOP_SHARE: {
    status: true,
    code: 10023,
    message: "Stop share stream."
  },
  MEDIA_HANDLER_CREATE_COMPLETE: {
    status: true,
    code: 10024,
    message: "Create media stream handler complete."
  },
  MEDIA_HANDLER_DESTROY_COMPLETE: {
    status: true,
    code: 10025,
    message: "Destroy media stream handler complete."
  },
  MEDIA_HANDLER_FAILURE: {
    status: false,
    code: 10026,
    message: "Create media stream handler failure."
  },
  PUB_LOST: {
    status: false,
    code: 10027,
    message: "Your stream pub lost,please reconnect."
  },
  CAN_PULL_STREAMS: {
    status: true,
    code: 10028,
    message: "These streams are ready to be pulled. Would you like to pull them."
  },
  START_PULL: {
    status: true,
    code: 10029,
    message: "Start pull remote."
  },
  STOP_PULL: {
    status: true,
    code: 10030,
    message: "Stop pull remote."
  },
  VOLUME_CHANGE: {
    status: true,
    code: 10031,
    message: "Volume change."
  },
  CAN_PULL_SHARES: {
    status: true,
    code: 10032,
    message: "These shares are ready to be pulled. Would you like to pull them."
  },
  SIGNAL_SERVER_DISCONNECTED: {
    status: false,
    code: 10034,
    message: "Signal server disconnected,will reconnect."
  },
  MEDIA_SERVER_DISCONNECTED: {
    status: false,
    code: 10035,
    message: "Media server disconnected,will reconnect."
  },
  ROOM_INFO_UPDATE: {
    status: true,
    code: 10036,
    message: "Room info updated."
  },
  GET_DISPATCH_MESSAGE: {
    status: true,
    code: 10037,
    message: "Get new dispatch message."
  },
  POLLING_USER: {
    status: true,
    code: 10038,
    message: "Polling the user stream."
  },
  QUALITYS: {
    status: true,
    code: 10039,
    message: "All streams qualitys."
  },
  NO_PERSSION_SHARING: {
    status: false,
    code: 10040,
    message: "No video sharing permission is available."
  },
}

window.ListenerManager = ListenerManager
export default ListenerManager
