export default class Fetch {
  static headers = {};

  static default_headers = {
    "Content-Type": "application/json",
    "Authorization": "77492789ad7d67z9f54asda9zxa123mx"
  };

  static async POST(url, body = {}, heads) {
    let headers = {};
    if (heads) {
      for (let h in heads) {
        headers[h] = heads[h];
      }
    } else {
      headers = Fetch.default_headers;
    }


    let res = await fetch(url, {
      method: 'POST',
      headers: headers,
      timeout: 3000,
      body: JSON.stringify(body)
    }).catch(function (error) {
      console.log("=========")
      console.log(error);
    });

    try {
      if (res.status == 200) {
        if (headers["Content-Type"] == "application/xml") {
          return await res.text();
        } else {
          return await res.json();
        }
      } else {
        console.log(ex.message);
        return false;
      }

    } catch (ex) {

      return false;
    }

  }

  static async PUT(url, body = {}, heads) {
    let headers = {};
    if (heads) {
      for (let h in heads) {
        headers[h] = heads[h];
      }
    } else {
      headers = Fetch.default_headers;
    }
    let res = await fetch(url, {
      method: 'PUT',
      headers: headers,
      timeout: 15000,
      body: JSON.stringify(body)
    }).catch(function (error) {
      console.log(error);
    });
    try {
      if (res.status == 200) {
        if (headers["Content-Type"] == "application/xml") {
          return await res.text();
        } else {
          return await res.json();
        }
      } else {
        console.log(ex.message);

        return false;
      }

    } catch (ex) {
      return false;
    }

  }

  static async GET(url, heads) {
    let headers = {};
    if (heads) {
      for (let h in heads) {
        headers[h] = heads[h];
      }
    } else {
      headers = Fetch.default_headers;
    }
    let res = await fetch(url, {
      method: 'GET',
      timeout: 15000,
      headers: headers
    }).catch(function (error) {
      console.log(error);
    });
    if (res.status == 200) {
      try {
        if (headers["Content-Type"] == "application/xml") {
          return await res.text();
        } else {
          return await res.json();
        }
      } catch (ex) {
        console.log(ex.message);
        return false;
      }
    } else {
      return false;
    }
  }

  static async DELETE(url, heads) {
    let headers = {};
    if (heads) {
      for (let h in heads) {
        headers[h] = heads[h];
      }
    } else {
      headers = Fetch.default_headers;
    }
    let res = await fetch(url, {
      method: 'DELETE',
      timeout: 15000,
      headers: headers
    }).catch(function (error) {
      console.log(error);
    });
    if (res.status == 200) {
      try {
        if (headers["Content-Type"] == "application/xml") {
          return await res.text();
        } else {
          return await res.json();
        }
      } catch (ex) {
        console.log(ex.message);
        return false;
      }
    } else {
      return false;
    }
  }
}
