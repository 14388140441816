import {uniq, random} from 'lodash';

import fetch from './utils/Fetch';
import ListenerManager from "./engine/ListenerManager";
import VSEngineManager from "./engine/VSEngineManager";
import ZGEngineManager from "./engine/ZGEngineManager";
import TXEngineManager from "./engine/TXEngineManager";
import {stat} from "copy-webpack-plugin/dist/utils/promisify";

class TRTCEngine {
  constructor(tenant_id, app_id, event_id, user_id, user_name, mode, server, listener_handler, room_info, director_turn, poll_duration, notify_range, vendor, application_id,apiAuth) {
    this._sdkv = "2.0.2"
    this._dataversion = "1.0.0"
    this._client_ip = ""
    this.enable_turn = false
    this.turn_info = ""
    this._application_id = application_id || "000"
    this._tenant_id = tenant_id
    this._app_id = app_id
    this._event_id = event_id
    this._user_id = user_id
    this._user_name = user_name
    this._mode = mode   //live,rtc
    this._room_info = room_info   //房间级信令
    this._server = server   //vrc_address
    this._token = ""    //事件鉴权token
    this._ils_token = ""    //信令鉴权token
    this._event_info = ""   //事件信令
    this._room_id = ""
    this._ils_server = ""
    this._media_server = ""
    this._vrx_server = ""
    this._director_turn = director_turn
    this._origin_ip = ""
    this._override_ip = ""
    this._stats_server = "" //https://stats-ct1.poc.videosolar.com,https://vss-td.poc.videosolar.com
    this._ice_servers = []
    this._retrying = false
    this._start_sync_members = false
    this._lastSid = -1
    this._sessionid = -1
    this._composeBug = false;
    this._members = []
    this._oldMembers = []
    this._viwertMembers = []
    this._viwerCount = 0
    this._timer_keepalive = ""
    this._timer_keepalive_check = ""
    this._timer_keepalive_noresponse_count = 0
    this._listenerManager = new ListenerManager(listener_handler)
    // this._engineHandler = new VSEngineManager(this)  //后续需要根据事件类型 初始化不同vendor的engine
    this._engineHandler = ""
    this._verdor = vendor
    if (!vendor || vendor == "vs1") {
      this._engineHandler = new VSEngineManager(this)
    } else if (vendor == "vs2") {
      this._engineHandler = new ZGEngineManager(this)
    } else if (vendor == "vs3") {
      this._engineHandler = new TXEngineManager(this)
    }
    this.ws = null
    this._mycamera_stream = null
    this._mycourseware_stream = null
    this._sharePending = false
    this._vrs_reconnecting = false  //timer属性
    this._initParams = null
    this._userCustomParams = null
    this._volumeTimer = {}
    this._timer_memberpoll = ""
    this._shareTimeoutTimer = null
    this._streamid_correct_timer = null
    this._media_server_check_timer = null
    this._isPubed = false
    this._isPulled = false
    this._poll_duration = poll_duration || 10000
    this._customParams = {
      internal: {
        nickName: this._user_name,
        camera: -1,
        microphone: -1
      }, extend: {}
    };
    this._notify_members = notify_range ? notify_range : []
    this._quality_data = []
    this._quality_interval = []
    this._stream_update_retry_count = 0
    this._temp_c = 0
    this._monitor_timer = null

    this.avg_output_bitrate = 0
    this.avg_inpput_bitrate = 0
    this.avg_output_bitrate_current = 0
    this.avg_inpput_bitrate_current = 0
    this.avg_bitrate_rs = {download_bit: "0Kbps", upload_bit: "0Kbps"}

    this._notify_target = ""

    this._inputDeviceCheck = 2


    window._vsrtc_data = {
      userId: this._user_id,
      eventId: this._tenant_id + "_" + this._event_id,
      members: [],
      auth: this._user_id.indexOf("vsnb") > 0 ? this._user_id.split("vsnb")[0] : this._user_id
    }
    //注册通用事件
    // this._listenerManager.registerCommonListener()
    //创建AudioContenxt句柄
    window.AudioContext = (window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext);
    if (window.AudioContext) {
      window.jAudioContext = new window.AudioContext();
      window.mixer_channel = null
      window.mixer_channel = window.jAudioContext.createMediaStreamDestination();
    } else {
      console.error('not support web audio api');

    }

    this._EVENT_CODE = {
      LOGIN: {
        code: "10004"
      },
      LEAVE: {
        code: "10005"
      },
      SYSTEM_CONNECT: {
        code: "10012"
      },
      PUBLISH_STREAM: {
        code: "10006"
      },
      RE_PUBLISH_STREAM: {
        code: "10007"
      },
      SUBSCRIBE_STREAM: {
        code: "10008"
      },
      RE_SUBSCRIBE_STREAM: {
        code: "10009"
      },
      CANCEL_SUBSCRIBE_STREAM: {
        code: "10010"
      },
      CREATE_STREAM: {
        code: "10013"
      },
    }
    this._ERROR_CODE = {
      SUCCESS: {
        code: "0"
      },
      VRC_TOKEN_FAILED: {
        code: "x5000001",
        message: "Get token from vrc failed."
      },
      VRC_SERVICE_UNREACHED: {
        code: "x5000002",
        message: "VRC service unreached."
      },
      VRS_SERVICE_UNREACHED: {
        code: "x5000003",
        message: "VRS service unreached."
      },
      VRC_CALLBACK_NO_ROOM: {
        code: "x5000004",
        message: "VRC callback no janus room."
      },
      VRS_TOKEN_FAILED: {
        code: "x5000005",
        message: "Get VRS token failed."
      },
      TRY_TO_VRX_SCHEDULE: {
        code: "x5000006",
        message: "Try to use vrx ,but unreached."
      },
      VRS_WS_ONCLOSE: {
        code: "x5000007",
        message: "VRS websocket exception."
      },
      MEDIA_SERVER_CONNECT_ERROR: {
        code: "x5000008",
        message: "Connect media server error."
      },
      PUB_STREAM_FAILURE: {
        code: "x5000009",
        message: "Publish stream failure."
      },

    }
    this._init(apiAuth)


  }

  setPushConfig(push_url, _video_dom_id, media) {
    if (media) {
      this._useVideo = media.video || null
      this._bitrate = media.bitrate || null
      this._resolution = media.resolution || null
      this._fps = media.fps || null
      this._video_deviceid = media.video_deviceid || null
      this._audio_deviceid = media.audio_deviceid || null
      this._audio_mode = media.audio_mode || "erasure"
      this._noise_suppression = media.noise_suppression || false
      window._audio_mode = media.audio_mode || "erasure"
    }
    if (this._verdor == "vs3") {
      this._engineHandler._init(push_url, _video_dom_id, media)

    }
  }

  async _init(ilsAuth) {
    this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.CONNECTING_SERVER)

    //1.获取token
    let get_token = true
    if (get_token) {
      this._token = this._app_id
    } else {
      //向外通知事件服务器连接失败

      this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.VRC_TOKEN_FAILURE)
      this.startReportEvent(this._EVENT_CODE.SYSTEM_CONNECT.code, this._ERROR_CODE.VRS_SERVICE_UNREACHED.code, this._ERROR_CODE.VRS_SERVICE_UNREACHED.message)

      return
    }

    let get_event = await fetch.GET(`${this._server}/rms/v1/room/${this._event_id}/apply?tenant_id=${this._tenant_id}&custom=${JSON.stringify(this._room_info)}`, {"Authorization": this._token,"X-PS-Flag":1})
    if (get_event) {
      if (get_event.code == 200) {
        if (get_event.data.room) {
          this._event_info = get_event.data

          this._room_id = this._event_info.room
          this._room_info = this._event_info.custom ? JSON.parse(this._event_info.custom) : {}
          sessionStorage["vs_host"] = ""
          if (this._event_info.sfuData && this._event_info.sfuData.Raw) {
            sessionStorage["vs_host"] = JSON.parse(this._event_info.sfuData.Raw).host;
          }
          if (this._event_info.additional && this._event_info.additional.scheduleConfig) {
            this._ils_server = this._event_info.additional.scheduleConfig.rtc_vrs_address
            this._media_server = this._event_info.additional.scheduleConfig.rtc_vsf_address
            this._vrx_server = this._event_info.additional.scheduleConfig.rtc_vrx_address
            // this._stats_server = this._event_info.additional.scheduleConfig.rtc_stats_address
            this._stats_server = this._event_info.additional.scheduleConfig.rtc_stats_address || this._stats_server
          }
          this._streamingServer = this._media_server + "/janus";
          //获取信令服务器token
          let get_ils_token = await fetch.POST(`${this._ils_server}/api/1.0/login` + "?" + ilsAuth, {
            userId: this._user_id,
            password: "raldoesfoaeudlitv"
          })

          //根据事件类型决定注册哪个media承载方的Listener,默认是vs
          if (get_ils_token) {
            this._ils_token = get_ils_token.token
            //开始连接ils-ws
            if (this._director_turn) {
              //走director_turn
              this.enable_turn = true
              this.turn_info = this._director_turn.turn.url
              this._ice_servers.push({
                urls: this._director_turn.turn.url,
                username: this._director_turn.turn.username,
                credential: this._director_turn.turn.credential
              });

            } else if (this._vrx_server) {
              let connect_vrx_server = await fetch.GET(this._vrx_server + "/v1/dns/resolve?host=" + this._media_server.split("://")[1].split(":")[0])

              if (connect_vrx_server) {
                let data = connect_vrx_server
                // if (data.records && data.records.length > 0 && data.records[0].target) {
                //   var ice = data.records[0];
                //
                //   self._ice_servers.push({
                //     urls: 'turn:' + ice.target,
                //     username: ice.meta.username,
                //     credential: ice.meta.password
                //   });
                //
                //
                // } else {
                //   //不走turn了，继续往下走
                // }
                //新turn对接
                if (data.records && data.records.length > 0) {
                  this._client_ip = data.records[0].clientIp
                  if (data.records.length == 1 && data.records[0].target == "0.0.0.0") {

                  } else if (data.records.length >= 1) {
                    var avaliable_turn = data.records.filter(function (v) {
                      return v.target != "0.0.0.0"
                    })
                    if (avaliable_turn.length > 0) {
                      var ice = avaliable_turn[0];
                      var self = this
                      if (ice.meta.vrf_list) {
                        ice.meta.vrf_list.forEach((v) => {
                          if (v.mgmt_ip == sessionStorage["vs_host"] || v.streaming_ip == sessionStorage["vs_host"]) {
                            self._origin_ip = v.streaming_ip || v.mgmt_ip
                            self._override_ip = v.relay_ip
                          }
                        })
                      }
                      this.enable_turn = true
                      this.turn_info = 'turn:' + ice.target + ":" + ice.meta.port
                      this._ice_servers.push({
                        urls: 'turn:' + ice.target + ":" + ice.meta.port,
                        username: ice.meta && ice.meta.username ? ice.meta.username : 'hiuniray',
                        credential: ice.meta && ice.meta.password ? ice.meta.password : 'hi123'
                      });
                    }
                  }

                  //继续往下走
                } else {
                  //继续往下走
                }
              } else {
                //不走turn了，继续往下走
                this.startReportEvent(this._EVENT_CODE.SYSTEM_CONNECT.code, this._ERROR_CODE.TRY_TO_VRX_SCHEDULE.code, this._ERROR_CODE.TRY_TO_VRX_SCHEDULE.message)

              }

            } else {
              //不走turn了，继续往下走
            }
            await this._memberpoll()
            this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.CONNECTED_SERVER)
            this.startReportEvent(this._EVENT_CODE.SYSTEM_CONNECT.code, this._ERROR_CODE.SUCCESS.code)

          } else {
            //向外通知信令服务器获取token失败
            this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.ILS_TOKEN_FAILURE)
            this.startReportEvent(this._EVENT_CODE.SYSTEM_CONNECT.code, this._ERROR_CODE.VRS_TOKEN_FAILED.code, this._ERROR_CODE.VRS_TOKEN_FAILED.message)

            return
          }
        } else {
          //向外通知暂无可用资源的告警
          this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.NO_AVAILABLE_ROOM)
          this.startReportEvent(this._EVENT_CODE.SYSTEM_CONNECT.code, this._ERROR_CODE.VRC_CALLBACK_NO_ROOM.code, this._ERROR_CODE.VRC_CALLBACK_NO_ROOM.message)

          return
        }
      } else {
        //向外通知暂无可用资源的告警
        this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.EVENT_INVALID)
        this.startReportEvent(this._EVENT_CODE.SYSTEM_CONNECT.code, get_event.code, get_event.message)

        return
      }

    } else {
      //向外通知事件服务器连接失败
      this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.VRC_SERVER_CONNECT_FAILURE)
      this.startReportEvent(this._EVENT_CODE.SYSTEM_CONNECT.code, this._ERROR_CODE.code, this._ERROR_CODE.message)

      return
    }
  }

  _emit_ui_data(data) {
    this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_UI_DATA, data)

  }

  async _connect_ils(initParams, customParams, max_resolution, notify_target) {
    var self = this;
    if ("WebSocket" in window) {
      self.leave(true)  //重新join，不要hungup
      //通知正在连接信令服务器
      this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_JOIN, ListenerManager.COMMON_MESSAGE.JOINING_ROOM, self._user_id)

      var connection_uri = 'wss://' + self._ils_server.split("://")[1] + '/api/1.0/ws/signals?_token=' + self._ils_token;

      this.ws = new WebSocket(connection_uri);
      this.ws.onopen = async function () {
        /*回调连接初始连接状态*/
        var self = this;
        self._customParams.extend = customParams;
        self._vrs_reconnecting = false
        self._timer_keepalive_noresponse_count = 0

        /*开始获取turn配置*/
        if (self.enable_turn) {
          self._customParams.extend.turnServer = self.enable_turn ? self.turn_info : "-"
        } else {
          self._customParams.extend.turnServer = "-"
        }
        self.ws.send(JSON.stringify({
          id: parseInt(random(100000000, 999999999, false)),
          type: 'join',
          lastSid: -1,
          notify: notify_target ? notify_target : [],
          data: {
            roomId: self._tenant_id + "_" + self._event_id + '',
            role: "host",
            type: "video",
            videoType: "camera",
            usePlanB: false,
            fields: {
              blind: initParams.blind ? initParams.blind : "off",
              muted: initParams.mute ? initParams.mute : "off",
              handsup: initParams.handsup ? initParams.handsup : "off",
              custom: JSON.stringify(self._customParams)
            }
          }
        }));

      }.bind(this);

      this.ws.onmessage = async function (evt) {
        let received_msg = JSON.parse(evt.data);
        switch (received_msg.type) {
          case 'join-ack':
            self._lastSid = received_msg.sid;
            self._sessionid = received_msg.data.sessionId;
            self._stream_update_retry_count++
            if (self._stream_update_retry_count > 30) {
              self._closePage()
            }

            self.startReportEvent(this._EVENT_CODE.LOGIN.code, this._ERROR_CODE.SUCCESS.code, "", {
              "sche": {
                "turn_enable": this.enable_turn,
                "turn": this.enable_turn ? this.turn_info : ""
              }
            })
            //开启keepalive轮询
            this._startKeepalive()
            //开启memberpoll轮询
            this._startMemberpoll()

            setTimeout(() => {
              self.getDevices("", max_resolution)
            }, 1000)
            window.onbeforeunload = function (event) {
              self.destroy()
            };
            break;
          case 'member-poll-ack':
            // received_msg.data.members ? self._updateMembers(received_msg.data.members) : "";
            break;
          case 'member-join':

            break;
          case 'compose':
            if (received_msg.sid <= self._lastSid) {
              return;
            }
            // self._lastSid = received_msg.sid;
            // if (!this._composeBug) {
            //   this._composeBug = true;
            //   return;
            // }
            var signals = [];
            if (received_msg.data.signals) {
              signals = received_msg.data.signals.filter(function (val, key) {
                return !val.roomId || val.roomId == (self._tenant_id + "_" + self._event_id);
              });
            } else {
              signals = [received_msg.data];
            }
            signals.forEach(async function (sig, sigIdx) {
              switch (sig.type) {
                case 'member-poll-ack':
                  // self._updateMembers(sig.data.members)
                  // if (sig.status == 400 && sig.data.error && sig.data.error.indexOf("Invalid session") >= 0) {
                  //   //对外通知会话结束
                  //   self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.SESSION_END)
                  // }
                  break;
                case 'member-join':
                  if (sig.sid < self._lastSid) {
                    return;
                  }
                  sig.data.members.forEach(function (v, k) {
                    if (v.userId.indexOf("mcu") < 0 && v.role != "viewer") {
                      var find = false;
                      var key = -1;
                      self._members.forEach(function (v1, k1) {
                        if (v.userId == v1.userId) {
                          key = k1;
                          find = true;
                        }
                      });
                      if (find) {
                        self._members[key] = v;
                      } else {
                        v.pullVideo = true;
                        self._members.push(v);
                      }
                      window._vsrtc_data.members = self._members

                      if (v.userId != self._user_id) {
                        //对外通知别人join成功
                        self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_JOIN, ListenerManager.COMMON_MESSAGE.JOINED_ROOM, v.userId)

                      } else {
                        //对外通知自己join成功
                        self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_JOIN, ListenerManager.COMMON_MESSAGE.JOINED_ROOM, self._user_id)

                      }

                    }

                  });
                  // self._memberpoll()
                  break;
                case 'member-update':
                  if (sig.sid < self._lastSid) {
                    return;
                  }
                  self._lastSid = sig.sid;
                  if (sig.data.members) {
                    sig.data.members.forEach(function (v, k) {
                      if (v.userId.indexOf("mcu") < 0 && v.role != "viewer") {
                        (function (val) {
                          self._customDiffNotify(val);

                        })(v)
                      }

                    });
                  }
                  //感觉有风险
                  // self._memberpoll()

                  break;
                case 'member-leave':
                  if (sig.sid < self._lastSid) {
                    return;
                  }
                  let if_me = false
                  if (sig.data.reason == "timeout") {
                    sig.data.members.forEach(function (v, k) {
                      if (v.role != "viewer") {
                        var tartgetKey = -1;
                        self._members.forEach(function (v1, k1) {
                          if (v1.userId == v) {
                            tartgetKey = k1;
                          }
                        });
                        if (tartgetKey >= 0) {
                          self._members.splice(tartgetKey, 1);
                          //对外通知是自己还是别人离开了
                          self.stopPull([v])
                          self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_LEAVE, ListenerManager.COMMON_MESSAGE.LEAVED_ROOM, v)
                          if (v == self._user_id) {
                            if_me = true
                          }
                        }
                      }

                    });
                  } else {
                    if (sig.data.members && sig.data.members.length > 0) {
                      sig.data.members.forEach(function (v, k) {
                        if (v.role != "viewer") {
                          var tartgetKey = -1;
                          self._members.forEach(function (v1, k1) {
                            if (v1.userId == v.userId) {
                              tartgetKey = k1;
                            }
                          });
                          if (tartgetKey >= 0) {
                            self._members.splice(tartgetKey, 1);
                            //通知是自己还是别人离开了
                            self.stopPull([v.userId])
                            self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_LEAVE, ListenerManager.COMMON_MESSAGE.LEAVED_ROOM, v.userId)
                          }
                        }
                        if (v.userId == self._user_id) {
                          if_me = true
                        }

                      });

                    }
                  }
                  if (if_me) {
                    self.leave(true)
                  } else {
                    // self._memberpoll()
                  }
                  break;
                case 'message':
                  if (sig.sid < self._lastSid) {
                    return;
                  }
                  self._lastSid = sig.sid;
                  break;
                case "dispatch":
                  // if (sig.sid < self._lastSid) {
                  //   return;
                  // }
                  self._lastSid = sig.sid;
                  if (sig.data.notify && sig.data.notify.type == "updateRoomInfo") {    //roomInfo change
                    let get_room_info = await fetch.GET(self._server + "/rms/v1/room/getRoomCustom/" + self._event_id + "?tenant_id=" + self._tenant_id, {"Authorization": self._token,"X-PS-Flag":"1"})
                    if (get_room_info) {
                      if (get_room_info && get_room_info.data.custom) {
                        //对外通知新的room_info
                        self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_ROOM_INFO_UPDATE, ListenerManager.COMMON_MESSAGE.ROOM_INFO_UPDATE, "", JSON.parse(get_room_info.data.custom))

                      }
                    }

                  } else {
                    //对外通知自定义消息
                    self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_DISPATCH_MESSAGE, ListenerManager.COMMON_MESSAGE.GET_DISPATCH_MESSAGE, "", sig.data)
                  }
                  break;
              }
            });
            break;
          case "keepalive-ack":
            self._timer_keepalive_noresponse_count = 0
            if (received_msg.status == 400 && received_msg.data.error && received_msg.data.error.indexOf("Invalid session") >= 0) {
              //对外通知房间结束
              self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.SESSION_END)
            } else if (received_msg.status == 404) {
              //对外通知房间结束
              self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.SESSION_END)
            } else if (received_msg.status == 200 && received_msg.data) {
              if (received_msg.data.viewerCount) {
                self._viwerCount = received_msg.data.viewerCount
              } else {
                self._viwerCount = 0
              }
            }

            break;
          case "hungup-ack":

            break

        }

      }.bind(this);

      this.ws.onclose = function (evt) {
        // self.startReportEvent(self._EVENT_CODE.LOGIN.code, self._ERROR_CODE.VRS_WS_ONCLOSE.code, self._ERROR_CODE.VRS_WS_ONCLOSE.message)
      }.bind(this);
    } else {

    }
  }

  _keepalive() {
    let self = this
    if (self._sessionid != -1) {
      self.ws.send(JSON.stringify({
        id: parseInt(random(100000000, 999999999, false)),
        type: 'keepalive',
        lastSid: -1,
        sessionId: self._sessionid,
        data: {
          roomId: self._tenant_id + "_" + self._event_id
        }
      }));
    }

  }

  _dispatch(info) {
    let self = this
    self.ws.send(JSON.stringify({
      id: parseInt(random(100000000, 999999999, false)),
      type: 'dispatch',
      lastSid: self._lastSid,
      sessionId: self._sessionid,
      data: {
        notify: {
          type: 'updateRoomInfo',
          message: JSON.stringify(info)
        }
      }
    }));
  }

  _startKeepalive() {
    let self = this
    if (this._timer_keepalive) {
      clearInterval(this._timer_keepalive)
      this._timer_keepalive = null;
    }
    if (this._timer_keepalive_check) {
      clearInterval(this._timer_keepalive_check)
      this._timer_keepalive_check = null
    }
    this._timer_keepalive = setInterval(function () {
      self._keepalive()
    }, 5000)

    this._timer_keepalive_check = setInterval(function () {
      self._timer_keepalive_noresponse_count++
      if (self._timer_keepalive_noresponse_count >= 5) {  //  30秒都没收到keepalive的响应，需要重连
        self._vrs_reconnecting = true
        self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.SIGNAL_SERVER_DISCONNECTED)

        self.join(self._initParams, self._userCustomParams, self._notify_target)
      }
    }, 5000)
  }

  async _memberpoll() {
    let self = this
    let pollingData = {
      id: parseInt(random(100000000, 999999999, false)),
      lastSid: -1,
      type: "member-poll",
      sessionId: "",
      data: {
        roomId: self._tenant_id + "_" + self._event_id + ''
      }
    };
    let get_members = await fetch.POST(`${this._ils_server}/api/1.0/signals`, pollingData, {
      "Authorization": "Bearer " + self._ils_token,
      "Content-Type": "application/json"
    })
    if (get_members.status == 200) {
      get_members.data.members ? self._updateMembers(get_members.data.members) : "";
      this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_MEMBERLIST_UPDATE, ListenerManager.COMMON_MESSAGE.MEMBER_LIST_UPDATE, "", self.getMembers())
    }

  }

  _startMemberpoll() {
    let self = this
    if (this._timer_memberpoll) {
      clearInterval(this._timer_memberpoll)
      this._timer_memberpoll = null
    }
    this._memberpoll()
    self._timer_memberpoll = setInterval(function () {
      self._temp_c++
      self._memberpoll()
      // if(self._temp_c>=10){
      //   clearInterval(self._timer_memberpoll)
      // }
    }, self._poll_duration)

  }

  _updateMembers(mems) {
    var self = this;
    if (!mems) return;
    self._members = mems.filter((v) => {
      return v.userId.toLowerCase().indexOf("mcu") < 0 && v.role != "viewer"
    })

    this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_MEMBERLIST_UPDATE, ListenerManager.COMMON_MESSAGE.MEMBER_LIST_UPDATE, "", self.getMembers())

    window._vsrtc_data.members = self._members
  }

  _customDiffNotify(params) {
    if (!params || !params.userId) return;
    var self = this;
    var target = self._members.filter(function (v) {
      return v.userId == params.userId;
    });
    var needNotify = [];
    if (target.length > 0) {
      if (target[0].blind != params.blind) {
        needNotify.push("blind");

      }
      if (target[0].coursewareStreamId != params.coursewareStreamId) {
        needNotify.push("coursewareStreamId");

      }
      if (params.custom) {
        if (JSON.stringify(JSON.parse(target[0].custom).extend) != JSON.stringify(JSON.parse(params.custom).extend)) {
          needNotify.push("custom");

        }
      }

      if (target[0].muted != params.muted) {
        needNotify.push("muted");

      }
      if (target[0].streamId != params.streamId) {
        needNotify.push("streamId");
      }
    }

    var orgK = -1;
    for (var i = 0; i < self._members.length; i++) {
      if (self._members[i] && self._members[i].userId == params.userId) {
        orgK = i;
      }
    }
    if (orgK >= 0) {
      self._members[orgK] = params;
    }
    let change = false
    needNotify.forEach(function (v, k) {
      if (v == "blind") {
        //向外通知blind的变化
        let videoStatus = params.blind;
        self._engineHandler._blind(target[0].userId, videoStatus)
        self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_BLIND, ListenerManager.COMMON_MESSAGE.BLIND_CHANGE, target[0].userId)
        change = true
      }
      if (v == "muted") {
        var audioStatus = params.muted;
        //向外通知muted的变化

        self._engineHandler._mute(target[0].userId, audioStatus)
        self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_MUTE, ListenerManager.COMMON_MESSAGE.MUTED_CHANGE, target[0].userId)
        change = true
      }
      if (v == "streamId") {
        //向外通知有人推流的streamId的变化
        change = true
      }
      if (v == "coursewareStreamId") {
        //向外通知有人推课件的coursewareStreamId的变化
        change = true
      }
      if (v == "custom") {
        //向外通知custom的变化
        self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_CUSTOMPARAMS_UPDATE, ListenerManager.COMMON_MESSAGE.USER_CUSTOMPARAMS_CHANGE, target[0].userId)
        change = true
      }

    });
    if (change) {
      this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_MEMBERLIST_UPDATE, ListenerManager.COMMON_MESSAGE.MEMBER_LIST_UPDATE, "", self.getMembers())
    }

  }

  _updateCustom(user_id, custom, notify_target) {
    this.ws.send(JSON.stringify({
      id: parseInt(random(100000000, 999999999, false)),
      type: 'custom-state',
      lastSid: this._lastSid,
      receivers: [user_id],
      sessionId: this._sessionid,
      notify: notify_target ? notify_target : (this._findNotifyTargetUsers(this.notify_target_type) ? this._findNotifyTargetUsers(this.notify_target_type) : []),
      data: {
        custom: JSON.stringify(custom),
      }
    }));
  }

  _addGuardOfStreamIdSync(userId, func) {
    // TODO 做好用户是否存在和streamId是否已经更新完成的保护
    let c = 500
    let self = this
    let guard = setInterval(() => {
      let target = self.getMembers([userId])
      if (target.length > 0 && target[0].streamId && target[0].streamId != "0") {
        clearInterval(guard)
        func()
      } else {
        c--
        if (c == 0) {
          clearInterval(guard)
          //向外通知未知异常
        }
      }

    }, 100)
  }

  _addGuardOfCoursewareStreamIdSync(userId, func) {
    // TODO 做好用户是否存在和streamId是否已经更新完成的保护
    let c = 500

    let guard = setInterval(() => {
      let target = this.getMembers([userId])
      if (target.length > 0 && target[0].coursewareStreamId && target[0].coursewareStreamId != "0") {
        clearInterval(guard)
        func()
      } else {
        c--
        if (c == 0) {
          clearInterval(guard)
          //向外通知位置异常
        }
      }

    }, 100)
  }

  _emitStartPush(userId, streamId) {
    let self = this

    if (userId == this._user_id) {
      this._stateUpdate(userId, {streamId: streamId + ""})
    }

    // TODO 做好用户是否存在和streamId是否已经更新完成的保护
    this._addGuardOfStreamIdSync(userId, function () {
      self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_START_PUSH, ListenerManager.COMMON_MESSAGE.START_PUSH, userId)
      self.startReportEvent(self._EVENT_CODE.PUBLISH_STREAM.code, self._ERROR_CODE.SUCCESS.code)

    })
  }

  _emitStopPush(userId, notify_target) {
    let self = this
    if (userId == this._user_id) {
      this._stateUpdate(userId, {streamId: "0"}, notify_target ? notify_target : [])
    }
    this._removeVolumeTimer(userId)
    this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_STOP_PUSH, ListenerManager.COMMON_MESSAGE.STOP_PUSH, userId)

  }

  _emitRemoteStreamReady(streams) {
    //TODO 通知之前保证这些人是在ILS中是ok的
    let self = this
    let feeds = Object.keys(streams).filter((v) => {
      return v.indexOf("-courseware-obj-") < 0
    })
    if (feeds.length > 0) {
      let temp = this.getMembers(feeds)
      if (temp.length > 0) {
        feeds = temp
      } else {
        feeds = feeds.map((v) => {
          return {userId: v, streamId: "0"}
        })
      }
    }
    let share = Object.keys(streams).filter((v) => {
      return v.indexOf("-courseware-obj-") >= 0
    }).map((v) => {
      return v.split("-")[0]
    })

    if (share.length > 0) {
      let temp = this.getMembers(share)
      if (temp.length > 0) {
        // share = temp
        share = share.map((v) => {
          return {userId: v, streamId: "0"}
        })
      } else {
        share = share.map((v) => {
          return {userId: v, streamId: "0"}
        })
      }
    }

    //人流的处理
    let available_feeds = feeds.filter((v) => {
      return v.streamId && v.streamId != "0"
    })
    let waiting_feeds = feeds.filter((v) => {
      return !v.streamId || v.streamId == "0"
    })
    if (available_feeds.length > 0) {
      let target = available_feeds.filter((v) => {
        return v.userId != self._user_id
      })
      if (target.length > 0) {
        target = target.map((v) => {
          return v.userId
        })

        this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_CAN_PULL_STREAM, ListenerManager.COMMON_MESSAGE.CAN_PULL_STREAMS, target)

      }
    }
    waiting_feeds.forEach((feed) => {
      if (feed.userId != self._user_id) {
        self._addGuardOfStreamIdSync(feed.userId, function () {
          self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_CAN_PULL_STREAM, ListenerManager.COMMON_MESSAGE.CAN_PULL_STREAMS, [feed.userId])

        })
      }
    })

    //=====share的处理
    let available_share = share.filter((v) => {
      return v.coursewareStreamId && v.coursewareStreamId != "0"
    })
    let waiting_share = share.filter((v) => {
      return !v.coursewareStreamId || v.coursewareStreamId == "0"
    })

    if (available_share.length > 0) {

      let target = available_share.filter((v) => {
        return v.userId != self._user_id
      })
      if (target.length > 0) {
        target = target.map((v) => {
          return v.userId
        })

        this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_CAN_PULL_SHARE, ListenerManager.COMMON_MESSAGE.CAN_PULL_SHARES, target)

      }
    }
    waiting_share.forEach((share) => {
      if (share.userId != self._user_id) {
        self._addGuardOfStreamIdSync(share.userId, function () {

          self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_CAN_PULL_SHARE, ListenerManager.COMMON_MESSAGE.CAN_PULL_SHARES, [share.userId])

        })
      }
    })
  }

  _emitStartPull(userId) {
    //TODO 通知之前保证这些人是在ILS中是ok的
    this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_START_PULL, ListenerManager.COMMON_MESSAGE.START_PULL, userId)
    this.startReportEvent(this._EVENT_CODE.SUBSCRIBE_STREAM.code, this._ERROR_CODE.SUCCESS)
  }

  _emitStopPull(userId) {
    //TODO 通知之前保证这些人是在ILS中是ok的
    this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_STOP_PULL, ListenerManager.COMMON_MESSAGE.STOP_PULL, userId)

  }

  _emitStartShare(userId, coursewareStreamId) {
    //TODO 做好用户是否存在和streamId是否已经更新完成的保护
    let self = this
    userId = userId.split("-")[0]
    if (userId == this._user_id) {
      this._stateUpdate(userId, {coursewareStreamId: coursewareStreamId + ""})
    }
    this._addGuardOfCoursewareStreamIdSync(userId, function () {
      self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_START_SHARE, ListenerManager.COMMON_MESSAGE.START_SHARE, userId)

    })

  }

  _emitStopShare(userId) {
    let self = this
    userId = userId.split("-")[0]
    if (userId == this._user_id) {
      this._stateUpdate(userId, {coursewareStreamId: "0"})
    }
    this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_STOP_SHARE, ListenerManager.COMMON_MESSAGE.STOP_SHARE, userId)

  }

  _emitMediaFinish(status) {
    let self = this
    if (status == 1) {
      if (this._media_server_check_timer) {
        clearInterval(this._media_server_check_timer)
        this._media_server_check_timer = null

      }
      this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.MEDIA_HANDLER_CREATE_COMPLETE)
      this.startReportEvent(this._EVENT_CODE.CREATE_STREAM.code, this._ERROR_CODE.SUCCESS.code)
    } else if (status == 2) {
      this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.MEDIA_HANDLER_DESTROY_COMPLETE)

    } else {
      this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_SYSTEM_CONNECT, ListenerManager.COMMON_MESSAGE.MEDIA_HANDLER_FAILURE)
      this.startReportEvent(this._EVENT_CODE.CREATE_STREAM.code, this._ERROR_CODE.MEDIA_SERVER_CONNECT_ERROR.code, this._ERROR_CODE.MEDIA_SERVER_CONNECT_ERROR.message)

      if (!this._media_server_check_timer) {
        this.createStream(this._isPubed, this._isPulled)
        this._media_server_check_timer = setInterval(() => {
          self.createStream(self._isPubed, self._isPulled)
        }, 5000)
      }

    }
  }

  _emitPubFailure(status) {
    this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_MEDIA_HANDLER_ERROR, ListenerManager.COMMON_MESSAGE.PUB_LOST)
    this.startReportEvent(this._EVENT_CODE.PUBLISH_STREAM.code, this._ERROR_CODE.PUB_STREAM_FAILURE.code, this._ERROR_CODE.PUB_STREAM_FAILURE.message)
  }

  _noPerssionShare() {
    this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_NO_SCREEN_SHARING_EXTENSION, ListenerManager.COMMON_MESSAGE.NO_PERSSION_SHARING)

  }

  _stateUpdate(userId, data, notify_target) {
    let self = this
    self.ws.send(JSON.stringify({
      id: parseInt(random(100000000, 999999999, false)),
      type: 'state-update',
      lastSid: self._lastSid,
      sessionId: self._sessionid,
      receivers: [userId],
      notify: this._findNotifyTargetUsers(this.notify_target_type) ? this._findNotifyTargetUsers(this.notify_target_type) : [],
      data: {
        fields: data
      }
    }));
  }

  changeNotifyMembers(members) {
    this._notify_members = members
  }

  _streamUpdate(streamId, notify_target) {
    this.ws.send(JSON.stringify({
      id: parseInt(random(100000000, 999999999, false)),
      type: 'stream-update',
      lastSid: this._lastSid,
      sessionId: this._sessionid,
      streamId: streamId + '',
      notify: this._findNotifyTargetUsers(this.notify_target_type) ? this._findNotifyTargetUsers(this.notify_target_type) : [],
      data: {"test": "test"}
    }));

  }

  _addVolumeTimer(userId, s) {
    if (userId && userId.indexOf("courseware-obj") >= 0 || !this._volumeMonitor) {
      return
    }
    try {
      (function (u, self, stream) {

        var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        var source;

        if (u == self._user_id) {
          source = audioCtx.createMediaStreamSource(s ? s : self._engineHandler._mystream);
          let scriptProcessor = null;
          let isRecord = true;
          source = audioCtx.createMediaStreamSource(s ? s : self._engineHandler._mystream);
          // 创建一个音频分析对象，采样的缓冲区大小为4096，输入和输出都是单声道
          scriptProcessor = audioCtx.createScriptProcessor(4096, 1, 1);
          // 将该分析对象与麦克风音频进行连接
          source.connect(scriptProcessor);
          // 此举无甚效果，仅仅是因为解决 Chrome 自身的 bug
          scriptProcessor.connect(audioCtx.destination);
          // 开始处理音频
          scriptProcessor.onaudioprocess = (e) => {
            if (isRecord) {
              // 获得缓冲区的输入音频，转换为包含了PCM通道数据的32位浮点数组
              const buffer = e.inputBuffer.getChannelData(0);
              // 获取缓冲区中最大的音量值
              const maxVal = Math.max(...buffer);
              // 显示音量值
              let mv = Math.round(maxVal * 100);
              mv = mv == 0 ? 1 : mv
              self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_VOLUME_CHANGE, ListenerManager.COMMON_MESSAGE.VOLUME_CHANGE, u, mv)

            }
          };
        } else {
          var analyser = audioCtx.createAnalyser();
          analyser.minDecibels = -90;
          analyser.maxDecibels = -10;
          analyser.smoothingTimeConstant = 0.1;

          var distortion = audioCtx.createWaveShaper();
          var gainNode = audioCtx.createGain();
          var biquadFilter = audioCtx.createBiquadFilter();
          var convolver = audioCtx.createConvolver();
          let is_share = false
          if (u.indexOf("courseware-obj") >= 0) {
            u = u.split("-")[0]
            is_share = true
          }
          let target = self.getMembers([u])
          if (target.length > 0) {
            if (is_share) {
              source = audioCtx.createMediaStreamSource(self._engineHandler._streams[target[0].coursewareStreamId]);
            } else {
              source = audioCtx.createMediaStreamSource(self._engineHandler._streams[target[0].streamId]);
            }

          } else {
            return
          }

          source.connect(distortion);
          distortion.connect(biquadFilter);
          biquadFilter.connect(gainNode);
          convolver.connect(gainNode);
          gainNode.connect(analyser);
          // analyser.connect(audioCtx.destination);
          analyser.fftSize = 256;
          var bufferLengthAlt = analyser.frequencyBinCount;
          var dataArrayAlt = new Uint8Array(bufferLengthAlt);
          if (self._volumeTimer[u]) clearInterval(self._volumeTimer[u])
          self._volumeTimer[u] = setInterval(() => {
            analyser.getByteFrequencyData(dataArrayAlt);  //0-255

            let volume = parseInt(JSON.stringify(Math.max.apply(null, dataArrayAlt)))
            volume = volume == 0 ? 1 : volume
            self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_VOLUME_CHANGE, ListenerManager.COMMON_MESSAGE.VOLUME_CHANGE, is_share ? u + "-share" : u, volume)

          }, 300)

        }

      })(userId, this, s)

    } catch (ex) {
      console.error(ex)
    }

  }

  _removeVolumeTimer(userId) {
    if (!this._volumeTimer[userId]) return
    clearInterval(this._volumeTimer[userId])
    delete this._volumeTimer[userId]

  }

  /*如下是对外暴露的统一方法*/

  destroy() {
    let self = this
    //离开房间
    this.leave()

  }

  _findNotifyTargetUsers(notify_target_type) {
    let members = []
    if (notify_target_type && notify_target_type.length > 0) {
      if (this._members && this._members.length > 0) {
        this._members.forEach((v) => {
          let custom = v.custom ? JSON.parse(v.custom) : ""
          if (custom && custom.extend && custom.extend.room_active_role && notify_target_type.indexOf(custom.extend.room_active_role) >= 0) {
            members.push(v.userId)
          }
        })
      }
      if (members.length > 0) {
        if (members.indexOf(this._user_id) < 0) {
          members.push(this._user_id)

        }
      }
    }

    return members

  }

  join(initParams, customParams, notify_target_type) {
    localStorage["local_volume_preview_enable"] = ""
    this._initParams = initParams
    this.notify_target_type = notify_target_type || -1
    this._userCustomParams = customParams
    this._connect_ils(this._initParams, this._userCustomParams, initParams.max_resolution, this._findNotifyTargetUsers(this.notify_target_type))
  }

  leave(nohungup, notify_target) {
    let self = this
    //关闭摄像头
    this.stopPreview()
    //停止流媒体句柄

    if (!nohungup) {
      this.destroyStream()
      if (this.ws) {
        this.ws.send(JSON.stringify({
          id: parseInt(random(100000000, 999999999, false)),
          type: 'hungup',
          lastSid: -1,
          notify: self._findNotifyTargetUsers(self.notify_target_type) ? self._findNotifyTargetUsers(self.notify_target_type) : [],
          sessionId: this._sessionid
        }));
      }
      self._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_LEAVE, ListenerManager.COMMON_MESSAGE.LEAVED_ROOM, self._user_id)
      self.startReportEvent(this._EVENT_CODE.LEAVE.code, this._ERROR_CODE.SUCCESS.code)
    }
    if (!this._vrs_reconnecting) {
      if (this._timer_keepalive_check) {
        clearInterval(this._timer_keepalive_check)
        this._timer_keepalive_check = null

      }
    }

    if (this._timer_keepalive) {
      clearInterval(this._timer_keepalive)
      this._timer_keepalive = null
    }
    if (this._timer_memberpoll) {
      clearInterval(this._timer_memberpoll)
      this._timer_memberpoll = null
    }


    if (self.ws) {
      self.ws.close()
      self.ws = null
    }


  }

  kickOut(members) {
    this.ws.send(JSON.stringify({
      id: parseInt(random(100000000, 999999999, false)),
      type: 'kick',
      lastSid: this._lastSid,
      receivers: members,
      sessionId: this._sessionid,
      data: {}
    }));
  }

  getMembers(members) {
    var self = this;
    var rs = []
    if (members) {
      rs = this._members.filter(function (v, k) {
        let flag = false
        members.forEach((v1) => {
          if (v1 == v.userId || (v1 + "" == v.userId + "")) {
            flag = true
          }
        })
        return flag
      })
    } else {
      rs = this._members
    }
    return rs.map(function (v, k) {
      return {
        userId: v.userId,
        streamId: v.streamId ? v.streamId : "0",
        stream: self._user_id == v.userId ? self._mycamera_stream : self._engineHandler._getStream(v.userId, v.streamId),
        coursewareStreamId: v.coursewareStreamId ? v.coursewareStreamId : "0",
        coursewareStream: self._user_id == v.userId ? self._mycourseware_stream : self._engineHandler._getShareStream(v.userId, v.coursewareStreamId),
        userName: JSON.parse(v.custom).internal ? decodeURIComponent(JSON.parse(v.custom).internal.nickName) : "",
        ioDevice: {
          camera: JSON.parse(v.custom).internal ? JSON.parse(v.custom).internal.camera : -1,
          microphone: JSON.parse(v.custom).internal ? JSON.parse(v.custom).internal.microphone : -1
        },
        deviceInfo: {blind: v.blind, muted: v.muted},
        customParams: v.custom ? JSON.parse(v.custom).extend : "",
        sharingApp: false,
      }
    });

  }

  getMe() {
    return this.getMembers([this._user_id])

  }

  updateRoomInfo() {

  }

  updateCustomParam(user_id, custom, notify_target) {
    let target = this._members.filter((v) => {
      return v.userId == user_id
    })
    if (target.length > 0) {
      let target_custom = target[0].custom ? JSON.parse(target[0].custom) : {}
      target_custom.extend = custom
      this._updateCustom(user_id, target_custom, notify_target)

    }
  }

  getMyInfo() {

  }

  getRemoteInfo() {

  }

  getViewer() {

  }

  updateNickname(userId, nickName) {
    var self = this;
    if (!userId || !nickName) {

      return;
    } else {
      var previous = self.getMembers([userId]);
      if (previous.length > 0) {
        var previousValue = previous[0];
        if (previousValue.nickName != nickName) {
          var target = self._members.filter(function (v1, k1) {
            return v1.userId == userId;
          });
          var custom = "";
          if (target.length > 0) {
            custom = target[0].custom ? JSON.parse(target[0].custom) : "";
            custom.internal.nickName = nickName;

            self._updateCustom(userId, custom)

          }

        }
      }

    }
  }

  dispatchMessage(data) {
    this.ws.send(JSON.stringify({
      id: parseInt(random(100000000, 999999999, false)),
      type: 'dispatch',
      lastSid: -1,
      sessionId: this._sessionid,
      data: {
        data: JSON.stringify(data)
      }
    }));
  }

  blind(status) {
    this._stateUpdate(this._user_id, {blind: status ? "on" : "off"})
  }

  blindRemote(userId, status) {
    this._stateUpdate(userId, {blind: status ? "on" : "off"})
  }

  mute(status) {
    this._stateUpdate(this._user_id, {muted: status ? "on" : "off"})
  }

  muteRemote(userId, status) {
    this._stateUpdate(userId, {muted: status ? "on" : "off"})
  }

  enableReceiveRemoteVideo(userId, status) {
    this._engineHandler.enableReceiveRemoteVideo(userId, status)
  }

  enableReceiveRemoteAudio(userId, status) {
    this._engineHandler.enableReceiveRemoteAudio(userId, status)
  }

  startPreview(cons, func) {
    var self = this
    if (this._verdor == "vs3") {
      if (func) func({})
    } else {
      let self = this
      let constraints = cons
      if (!constraints) {
        constraints = {video: {width: 1280, height: 720}, audio: true}
      }

      navigator.mediaDevices.getUserMedia(
        constraints
      ).then(function (stream) {
        self._mycamera_stream = typeof stream.stop === 'function' ? stream : stream.getTracks()[1];

        self._addVolumeTimer(self._user_id, stream)
        if (func) func(stream)
      }).catch(function (err) {
        if (func) func(ListenerManager.COMMON_MESSAGE.PERMISSION_DENY_DEVICES)
      });
    }


  }

  stopPreview(func) {
    if (this._verdor == "vs3") {
      this._engineHandler._stopPreview()
      if (func) func()
    } else {
      if (this._mycamera_stream && this._mycamera_stream.stop) this._mycamera_stream.stop()
      this._mycamera_stream = null
      if (func) func()
    }

  }

  static Tools_StartPreview(cons, func) {
    localStorage["local_volume_preview_enable"] = ""
    localStorage["local_volume_preview_value"] = ""
    let self = this
    let constraints = cons
    if (!constraints) {
      constraints = {video: {width: 1280, height: 720}, audio: true}
    }

    navigator.mediaDevices.getUserMedia(
      constraints
    ).then(function (stream) {
      window._mycamera_stream = typeof stream.stop === 'function' ? stream : stream.getTracks()[1];
      if (func) func({status: true, data: stream})
      var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      var source;

      if (!localStorage["local_volume_preview_enable"] || localStorage["local_volume_preview_enable"] == 0) {
        localStorage["local_volume_preview_enable"] = 1
        if (localStorage["local_volume_preview_enable"] == 0 || !localStorage["local_volume_preview_enable"]) {
          audioCtx.close()
          return
        }
        let scriptProcessor = null;
        let isRecord = true;
        source = audioCtx.createMediaStreamSource(stream);
        // 创建一个音频分析对象，采样的缓冲区大小为4096，输入和输出都是单声道
        scriptProcessor = audioCtx.createScriptProcessor(4096, 1, 1);
        // 将该分析对象与麦克风音频进行连接
        source.connect(scriptProcessor);
        // 此举无甚效果，仅仅是因为解决 Chrome 自身的 bug
        scriptProcessor.connect(audioCtx.destination);
        // 开始处理音频
        scriptProcessor.onaudioprocess = (e) => {
          if (isRecord) {
            // 获得缓冲区的输入音频，转换为包含了PCM通道数据的32位浮点数组
            const buffer = e.inputBuffer.getChannelData(0);
            // 获取缓冲区中最大的音量值
            const maxVal = Math.max(...buffer);
            // 显示音量值
            let mv = Math.round(maxVal * 100);
            mv = mv == 0 ? 1 : mv
            localStorage["local_volume_preview_value"] = mv
          }
        };

      }


    }).catch(function (err) {
      console.log(err)
      if (func) func({status: false, message: "No permission to get devices."})
    });

  }

  static Tools_StopPreview(func) {
    localStorage["local_volume_preview_enable"] = ""
    localStorage["local_volume_preview_value"] = ""
    if (window._mycamera_stream && window._mycamera_stream.stop) window._mycamera_stream.stop()
    window._mycamera_stream = null
    if (func) func()
  }

  static Tools_GetDecices(func, maxResolution) {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      if (func) func({status: false, message: "No permission to get devices."})
    } else {
      // 首先获取到流，获取流成功后再获取设备信息
      navigator.mediaDevices.getUserMedia({
        video: maxResolution ? maxResolution : {width: 1280, height: 720},
        audio: true
      }).then(gotMediaStream).then(gotDevices).catch(handleError);

    }

    function gotMediaStream(stream) {

      return navigator.mediaDevices.enumerateDevices();

    }

    function gotDevices(deviceInfos) {
      let rs = {video_input: [], audio_input: [], audio_output: []}
      rs.video_input = deviceInfos.filter((v) => {
        return v.kind === 'videoinput'
      })
      rs.audio_input = deviceInfos.filter((v) => {
        return v.kind === 'audioinput'
      })
      rs.audio_output = deviceInfos.filter((v) => {
        return v.kind === 'audiooutput'
      })
      if (rs.video_input.length == 0 && rs.audio_input.length == 0) {
        if (func) func({status: false, message: "no devices can be used."})
      } else {
        if (func) func({status: true, data: rs})

      }

    }

    function handleError(err) {
      if (func) func({status: false, message: "Error:No permission to get devices."})
    }
  }

  getDevices(func, maxResolution) {
    let self = this
    let custom = ""
    let me = this._members.filter((v) => {
      return v.userId == this._user_id
    })
    if (me.length > 0) {
      custom = me[0].custom ? JSON.parse(me[0].custom) : {}
    }
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      custom.internal.camera = -1
      custom.internal.microphone = -1
      self._updateCustom(self._user_id, custom)

      if (func) func(ListenerManager.COMMON_MESSAGE.NO_AVAILABLE_DEVICES)
    } else {
      // 首先获取到流，获取流成功后再获取设备信息
      // alert(JSON.stringify(maxResolution))
      navigator.mediaDevices.getUserMedia({
        video: maxResolution ? maxResolution : {width: 1280, height: 720},
        audio: true
      }).then(gotMediaStream).then(gotDevices).catch(handleError);

    }

    function gotMediaStream(stream) {
      return navigator.mediaDevices.enumerateDevices();

    }

    function gotDevices(deviceInfos) {
      self._inputDeviceCheck = 2
      let rs = {video_input: [], audio_input: [], audio_output: []}
      rs.video_input = deviceInfos.filter((v) => {
        return v.kind === 'videoinput'
      })
      rs.audio_input = deviceInfos.filter((v) => {
        return v.kind === 'audioinput'
      })
      rs.audio_output = deviceInfos.filter((v) => {
        return v.kind === 'audiooutput'
      })
      if (rs.video_input.length > 0) {
        custom.internal.camera = 1
      } else {
        custom.internal.camera = -1
      }

      if (rs.audio_input.length > 0) {
        custom.internal.microphone = 1
      } else {
        custom.internal.microphone = -1
      }
      if (rs.video_input.length == 0 && rs.audio_input.length == 0) {
        custom.internal.camera = -1
        custom.internal.microphone = -1
        console.log(deviceInfos)
        if (func) func(ListenerManager.COMMON_MESSAGE.NO_AVAILABLE_DEVICES)
      } else {
        if (func) func(rs)

      }
      self._updateCustom(self._user_id, custom)

    }

    function handleError(err) {
      if (err.message.indexOf("Requested device not found") >= 0) {
        self._inputDeviceCheck = self._inputDeviceCheck - 1
        if (self._inputDeviceCheck == 1) {
          navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true
          }).then(gotMediaStream).then(gotDevices).catch(handleError);
        }
        if (self._inputDeviceCheck == 0) {
          custom.internal.camera = -1
          custom.internal.microphone = -1
          self._updateCustom(self._user_id, custom)
          if (func) func(ListenerManager.COMMON_MESSAGE.PERMISSION_DENY_DEVICES)
        }

      } else {
        custom.internal.camera = -1
        custom.internal.microphone = -1
        self._updateCustom(self._user_id, custom)
        if (func) func(ListenerManager.COMMON_MESSAGE.PERMISSION_DENY_DEVICES)
      }

    }
  }

  createStream(push, pull, video_dom_id) {
    this._engineHandler._createStream(this._streamingServer, this._ice_servers, this._room_id, false, false, video_dom_id)
  }

  destroyStream() {
    this._engineHandler._destroyStream()
  }

  startPush(video, notify_target, _volumeMonitor) {
    let self = this
    this._isPubed = true
    this._volumeMonitor = _volumeMonitor == false ? false : true
    if (video) {
      this._useVideo = video.video || null
      this._bitrate = video.bitrate || null
      this._fps = video.fps || null
      this._resolution = video.resolution || null
      this._video_deviceid = video.video_deviceid || null
      this._audio_deviceid = video.audio_deviceid || null
      this._audio_mode = video.audio_mode || "erasure"
      this._noise_suppression = video.noise_suppression || false
      window._audio_mode = video.audio_mode || "erasure"
    }
    this._engineHandler._pushHandler(this._user_id, video)

    if (!this._streamid_correct_timer) {
      this._streamid_correct_timer = setInterval(() => {  //每5秒矫正一下推流的streamid，以防出现streamid更新失败的情况
        let me = self.getMe()
        if (me.length > 0 && me[0].streamId != self._engineHandler._streamId) {
          self._stream_update_retry_count++
          if (self._stream_update_retry_count <= 30) {
            self._stateUpdate(self._user_id, {streamId: self._engineHandler._streamId + ""}, notify_target ? notify_target : [])

          } else {
            self._closePage()
          }
        }
      }, 5000)
    }

  }

  stopPush(notify_target) {
    this._isPubed = false
    if (this._streamid_correct_timer) {
      clearInterval(this._streamid_correct_timer)
      this._streamid_correct_timer = null
    }
    this._engineHandler._stopPushHandler()
    this._emitStopPush(this._user_id, notify_target)

  }

  startPullShare(members) {
    let self = this
    members.forEach((member, k) => {
      (function (m, key) {
        setTimeout(() => {
          self._engineHandler._newRemoteFeed(m + "-courseware-obj-", true)
        }, key * 1000)
      })(member, k)

    })

  }

  stopPullShare(members) {
    let self = this
    members.forEach((member, k) => {
      (function (m, key) {
        setTimeout(() => {
          self._engineHandler._detachRemoteFeed(m + "-courseware-obj-")
          self._removeVolumeTimer(m + "-courseware-obj-")
        }, key * 1000)
      })(member, k)
    })

  }

  startPull(members, video) {
    let self = this
    let s = true
    if (video === false) {
      s = false
    } else if (!video) {
      s = true
    } else {
      s = video
    }
    members.forEach((member, k) => {
      (function (m, key) {
        setTimeout(() => {
          self._engineHandler._newRemoteFeed(m, s)
        }, key * 1000)
      })(member, k)

    })

  }

  stopPull(members) {
    let self = this
    members.forEach((member, k) => {
      (function (m, key) {
        setTimeout(() => {
          self._engineHandler._detachRemoteFeed(m)
          self._emitStopPull(m)
          self._removeVolumeTimer(m)
          self._removeVolumeTimer(m + "-courseware-obj-")
        }, key * 1000)
      })(member, k)
    })
  }

  startListenRemote() {
    this._isPulled = true
    this._engineHandler._pullHandler(this._user_id)
  }

  stopListenRemote() {
    this._isPulled = false
    this._engineHandler._detachRemoteFeed()
    this._engineHandler._stopPullHandler()
  }

  startShareDesktop() {
    var self = this;
    this._sharePending = true;
    self._engineHandler._captureDeskTopScreen()
    this._shareTimeoutTimer = setTimeout(function () {
      if (self._sharePending) {
        //此时要通知分享失败
        self._engineHandler._stopShare()
        self._emitStopShare(self._user_id)

      }
    }, 20000);
  }

  startShareMedia(options, elm) {
    var self = this;
    this._sharePending = true;
    self._engineHandler._captureMedia({useAudio: true, bitrate: 1500000}, elm)
    this._shareTimeoutTimer = setTimeout(function () {
      if (self._sharePending) {
        //此时要通知分享失败
        self._engineHandler._stopShare()
        self._emitStopShare(self._user_id)

      }
    }, 20000);
  }

  startShareCanvas(options, elm) {
    var self = this;
    this._sharePending = true;
    self._engineHandler._captureCanvas({useAudio: true, bitrate: 1500000}, elm)
    this._shareTimeoutTimer = setTimeout(function () {
      if (self._sharePending) {
        //此时要通知分享失败
        self._engineHandler._stopShare()
        self._emitStopShare(self._user_id)

      }
    }, 20000);
  }

  stopShare() {
    this._engineHandler._stopShare()
    this._emitStopShare(this._user_id)
  }

  resetBitrateAndResolutionAnd(media) {
    let type = "replaceVideo"
    if (media.video && media.video.deviceId) {
      type = "replaceVideo"
    } else {
      type = "replaceAudio"
    }
    this._engineHandler._republishOwnFeed(media, type)
  }

  changeNoiseReductionPolicy(status) {
    if (status == "on") {
      this._audio_mode = "erasure"
      this._engineHandler._audio_mode = "erasure"
    } else {
      this._audio_mode = "normal"
      this._engineHandler._audio_mode = "normal"
    }
    this._engineHandler._republishOwnFeed()
  }

  async getRoomInfo(func) {
    let self = this
    let get_room_info = await fetch.GET(self._server + "/rms/v1/room/getRoomCustom/" + self._event_id + "?tenant_id=" + self._tenant_id, {"Authorization": self._token,"X-PS-Flag":"1"})
    if (get_room_info.code && get_room_info.code == 200 && get_room_info.data) {
      func(get_room_info)
    } else {
      var c = 0
      var trying = setInterval(async (v) => {
        c++
        get_room_info = await fetch.GET(self._server + "/rms/v1/room/getRoomCustom/" + self._event_id + "?tenant_id=" + self._tenant_id, {"Authorization": self._token,"X-PS-Flag":"1"})
        if (get_room_info.code && get_room_info.code == 200 && get_room_info.data) {
          clearInterval(trying)
          func(get_room_info)
        }
        if (c > 30) {
          clearInterval(trying)
          func({"error": "ai"})
        }
      }, 5000)
    }

  }

  async changeRoomInfo(info, func) {
    var self = this;
    let update_room_info = await fetch.PUT(self._server + "/rms/v1/room/changeRoomCustom/" + self._event_id + "?tenant_id=" + self._tenant_id, {custom: JSON.stringify(info)}, {"Authorization": this._token,"X-PS-Flag":"1"})
    if (update_room_info) {
      self._dispatch(info)
    }

    if (func) {
      func(update_room_info)
    }

  }

  mixerStream(stream) {
    window.jMixerAudioStream = stream
    window.jGain = window.jAudioContext.createGain();
    window.jGain.gain.value = 1;
    setTimeout(function () {
      var source2 = window.jAudioContext.createMediaStreamSource(window.jMixerAudioStream);
      source2.connect(window.jGain);
      if (window.mixer_channel) {
        window.jGain.connect(window.mixer_channel);
      }

    }, 1000)

  }

  changeCameraStreamVolume(volume) {
    if (volume < 0) {
      volume = 0
    }
    window.jCameraGain.gain.value = parseFloat(volume);
  }

  changeMixerStreamVolume(volume) {
    if (volume < 0) {
      volume = 0
    }
    window.jGain.gain.value = parseFloat(volume);
  }

  attachLocalMedia(dom, stream) {
    try {
      if (this._verdor == "vs3") {
        if (!this._engineHandler._vsEngineHandler) {
          this._engineHandler._init(this._engineHandler._video_push_url, this._engineHandler._video_dom_id)
        }
        this._engineHandler._startPreview()
        // document.getElementById(this._engineHandler._video_dom_id).getElementsByTagName('video')[0].srcObject = stream;
        // document.getElementById(this._engineHandler._video_dom_id).getElementsByTagName('video')[0].muted = true;
      } else {
        dom.srcObject = stream;
        dom.muted = "muted";
      }

    } catch (ex) {

    }

  }

  changeSpeaker(id) {
    var videos = document.querySelectorAll("video")
    var audios = document.querySelectorAll("audio")
    for (var i = 0; i < videos.length; i++) {
      videos[i].setSinkId(id)
    }
    for (var i = 0; i < audios.length; i++) {
      audios[i].setSinkId(id)
    }
  }

  startMonitorReport(username) {
    let self = this
    if (this._monitor_timer) {
      clearInterval(this._monitor_timer)
      this._monitor_timer = null
    }

    this._monitor_timer = setInterval(() => {
      let basic_data = {}
      //   packageLost: "",
      //   rtt: "",
      //   downloadBitrate: "",
      //   resolution: "",
      //   userId: ""
      var info = {"browser": "unknown", "version": "unknown"}
      if (window.vs_sdk_broswer) {
        info = new Browser();
      }
      for (let i of [...this._quality_data]) {
        let meta = {
          "appid": self._application_id || 'unknown',
          "ui": this._user_id.split("vsnb")[0],
          "v": this._dataversion,
          "tm": i.base.timestamp,
          "lei": 20001,
          "ei": this._event_id,
          "ai": 0,
          "ti": this._tenant_id,
          "m": "client",
          "sn": info.browser,
          "sv": info.version || "unknown",
          "deb": "",
          "den": "h5",
          "b": "",
          "bv": "",
          "udid": this._user_id.split("vsnb")[0],
          "sdkv": this._sdkv,
          "ip": this._client_ip || "",
          "cput": null,
          "args": ""
        }

        let elm = {
          type: 'video/audio',
          is_pub: false,
          userdata: {},
          // converge: {
          //   audio: i.data.audio,
          //   video: i.data.video,
          //   bandwidth: i.data.bandwidth,
          //   resolutions: i.data.resolutions,
          //   connectionType: i.data.connectionType
          // },
          ao: {},
          vo: {},
          ai: {},
          vi: {},
          bwe: {},
          ice: {},
          access: "wifi",
          sei: self._sessionid,
        }
        basic_data[i.base.user_id] = {userId: "", packageLost: "", resolution: "", downloadBitrate: "", rtt: ""}
        //需要把i.data.results按照Legency的方式进行拼接
        let ss = checkIfLegacyData(i.data.results) ? mergeLegacyData(i.data.results) : i.data.results
        ss.forEach(function (item) {
          basic_data[i.base.user_id]["userId"] = i.base.user_id
          if (item.type === 'ssrc') {
            var isAudio = item.mediaType === 'audio'; // audio or video
            var isSending = i.base.user_id == self._user_id; // sender or receiver
            if (isSending && isAudio) {     //publisher 的 audio (aout)
              elm.is_pub = true;
              elm["ao"]["nSSRC"] = parseInt(item.ssrc)
              elm["ao"]["sTransportId"] = item.transportId
              elm["ao"]["sCodecName"] = item.googCodecName || item.mimeType
              elm["ao"]["sTrackId"] = item.googTrackId || item.trackIdentifier
              elm["ao"]["nBytesSent"] = parseInt(item.bytesSent)
              elm["ao"]["nRtt"] = parseInt(item.googRtt)
              elm["ao"]["iJitterReceived"] = parseInt(item.googJitterReceived) || parseInt(item.jitter)
              elm["ao"]["iAudioInputLevel"] = parseInt(item.audioInputLevel) || item.audioLevel
              elm["ao"]["iEchoReturnLoss"] = parseInt(item.echoReturnLoss)
              elm["ao"]["iEchoReturnLossEnhancement"] = parseInt(item.echoReturnLossEnhancement)

              basic_data[i.base.user_id]["iAudioInputLevel"] = parseInt(item.audioInputLevel)

            }
            if (isSending && !isAudio) {    //publisher 的 video (vout)
              elm.is_pub = true;
              elm["vo"]["nSSRC"] = parseInt(item.ssrc)
              elm["vo"]["sTransportId"] = item.transportId
              elm["vo"]["sCodecName"] = item.googCodecName || item.mimeType
              elm["vo"]["sTrackId"] = item.googTrackId || item.trackIdentifier
              elm["vo"]["nBytesSent"] = parseInt(item.bytesSent)
              elm["vo"]["iPacketsLost"] = parseInt(item.packetsLost)
              elm["vo"]["iPacketsSent"] = parseInt(item.packetsSent)
              elm["vo"]["nRtt"] = parseInt(item.googRtt)
              elm["vo"]["iFirsReceived"] = parseInt(item.googFirsReceived) || item.firCount
              elm["vo"]["iNacksReceived"] = parseInt(item.googNacksReceived) || item.nackCount
              elm["vo"]["iPlisReceived"] = parseInt(item.googPlisReceived) || item.pliCount
              elm["vo"]["iFrameRateInput"] = parseInt(item.googFrameRateInput) || item.framesPerSecond
              elm["vo"]["iFrameRateSent"] = parseInt(item.googFrameRateSent) || item.framesPerSecond
              elm["vo"]["iFrameWidthInput"] = parseInt(item.frameWidthInput || item.googFrameWidthSent) || item.width
              elm["vo"]["iFrameWidthSent"] = parseInt(item.googFrameWidthSent) || item.frameWidth
              elm["vo"]["iFrameHeightInput"] = parseInt(item.frameHeightInput || item.googFrameHeightSent) || item.height
              elm["vo"]["iFrameHeightSent"] = parseInt(item.googFrameHeightSent) || item.frameHeight
              elm["vo"]["iFramesEncoded"] = parseInt(item.framesEncoded)
              elm["vo"]["iQpSum"] = parseInt(item.qpSum)
              elm["vo"]["iAvgEncodeMs"] = parseInt(item.googAvgEncodeMs) || item.totalEncodeTime
              elm["vo"]["iEncodeUsagePercent"] = parseInt(item.googEncodeUsagePercent)
              elm["vo"]["iAdaptationChanges"] = parseInt(item.googAdaptationChanges)
              elm["vo"]["bBandwidthLimitedResolution"] = item.googBandwidthLimitedResolution
              elm["vo"]["bCpuLimitedResolution"] = item.googCpuLimitedResolution
              elm["vo"]["nPlost"] = parseInt(elm["vo"]["iPacketsLost"] / elm["vo"]["iPacketsSent"])
              basic_data[i.base.user_id]["packageLost"] = (elm["vo"]["iPacketsLost"] / elm["vo"]["iPacketsSent"]).toFixed(3) * 100 + "%";
              if (elm["vo"]["iFrameWidthSent"] && elm["vo"]["iFrameHeightSent"]) {
                basic_data[i.base.user_id]["resolution"] = elm["vo"]["iFrameWidthSent"] + "*" + elm["vo"]["iFrameHeightSent"];
              }
            }
            if (!isSending && isAudio) {    //subscribe 的 audio (ain)
              if (!elm.is_pub) {
                elm.is_pub = false;
              }
              elm["ai"]["nSSRC"] = parseInt(item.ssrc)
              elm["ai"]["sTransportId"] = item.transportId
              elm["ai"]["sCodecName"] = item.googCodecName || item.mimeType
              elm["ai"]["sTrackId"] = item.googTrackId || item.trackIdentifier
              elm["ai"]["nBytesReceived"] = parseInt(item.bytesReceived)
              elm["ai"]["iPacketsLost"] = parseInt(item.packetsLost)
              elm["ai"]["iPacketsReceived"] = parseInt(item.packetsReceived)
              elm["ai"]["iJitterReceived"] = parseInt(item.googJitterReceived) || item.jitter
              elm["ai"]["iJitterBufferMs"] = parseInt(item.googJitterBufferMs) || item.jitterBufferDelay
              elm["ai"]["iPreferredJitterBufferMs"] = parseInt(item.googPreferredJitterBufferMs) || item.jitterBufferTargetDelay
              elm["ai"]["iAudioOutputLevel"] = parseInt(item.audioOutputLevel) || item.audioLevel
              elm["ai"]["iCurrentDelayMs"] = parseInt(item.googCurrentDelayMs)
              elm["ai"]["fAccelerateRate"] = parseInt(item.googAccelerateRate)
              elm["ai"]["fExpandRate"] = parseInt(item.googExpandRate)
              elm["ai"]["fPreemptiveExpandRate"] = parseInt(item.googPreemptiveExpandRate)
              elm["ai"]["fSpeechExpandRate"] = parseInt(item.googSpeechExpandRate)

            }
            if (!isSending && !isAudio) {   //subscribe 的 video (vin)
              if (!elm.is_pub) {
                elm.is_pub = false;
              }
              elm["vi"]["nSSRC"] = parseInt(item.ssrc)
              elm["vi"]["sTransportId"] = item.transportId
              elm["vi"]["sCodecName"] = item.googCodecName || item.mimeType
              elm["vi"]["sTrackId"] = item.googTrackId || item.trackIdentifier
              elm["vi"]["nBytesReceived"] = parseInt(item.bytesReceived)
              elm["vi"]["iPacketsLost"] = parseInt(item.packetsLost)
              elm["vi"]["iPacketsReceived"] = parseInt(item.packetsReceived)
              elm["vi"]["iFirsSent"] = parseInt(item.googFirsSent)
              elm["vi"]["iNacksSent"] = parseInt(item.googMinPlayoutDelayMs) || item.nackCount
              elm["vi"]["iPlisSent"] = parseInt(item.googPlisSent) || item.pliCount
              elm["vi"]["iFrameWidthReceived"] = parseInt(item.googFrameWidthReceived) || item.frameWidth
              elm["vi"]["iFrameHeightReceived"] = parseInt(item.googFrameHeightReceived) || item.frameHeight
              elm["vi"]["iFrameRateReceived"] = parseInt(item.googFrameRateReceived) || item.framesReceived
              elm["vi"]["iFrameRateDecoded"] = parseInt(item.googFrameRateDecoded) || item.framesDecoded
              elm["vi"]["iFrameRateOutput"] = parseInt(item.googFrameRateOutput) || item.framesPerSecond
              elm["vi"]["sCodecImplementationName"] = item.codecImplementationName || item.decoderImplementation
              elm["vi"]["iFramesDecoded"] = parseInt(item.framesDecoded)
              elm["vi"]["iJitterBufferMs"] = parseInt(item.googJitterBufferMs) || item.jitter
              elm["vi"]["iDecodeMs"] = parseInt(item.googDecodeMs)
              elm["vi"]["iMaxDecodeMs"] = parseInt(item.googMaxDecodeMs)
              elm["vi"]["iCurrentDelayMs"] = parseInt(item.googCurrentDelayMs) || item.jitterBufferDelay
              elm["vi"]["iRenderDelayMs"] = parseInt(item.googRenderDelayMs)
              elm["vi"]["iTargetDelayMs"] = parseInt(item.googTargetDelayMs) || item.jitterBufferTargetDelay
              elm["vi"]["iMinPlayoutDelayMs"] = parseInt(item.googMinPlayoutDelayMs)
              elm["vi"]["nPlost"] = parseInt(elm["vi"]["iPacketsLost"] / elm["vi"]["iPacketsReceived"])
              if (elm["vi"]["iFrameWidthReceived"] && elm["vi"]["iFrameHeightReceived"]) {
                basic_data[i.base.user_id]["resolution"] = elm["vi"]["iFrameWidthReceived"] + "*" + elm["vi"]["iFrameHeightReceived"];
              }

            }

          }
          if (item.type == "VideoBwe") {  //获取bwe
            elm["bwe"]["iActualEncBitrate"] = parseInt(item.googActualEncBitrate) || item.targetBitrate
            elm["bwe"]["iTargetEncBitrate"] = parseInt(item.googTargetEncBitrate)
            elm["bwe"]["iTransmitBitrate"] = parseInt(item.googTransmitBitrate) || item.targetBitrate
            elm["bwe"]["iRetransmitBitrate"] = parseInt(item.googRetransmitBitrate)
            elm["bwe"]["iAvailableReceiveBandwidth"] = parseInt(item.googAvailableReceiveBandwidth)
            elm["bwe"]["iAvailableSendBandwidth"] = parseInt(item.googAvailableSendBandwidth)
            elm["bwe"]["nBucketDelay"] = parseInt(item.googBucketDelay)

            basic_data[i.base.user_id]["downloadBitrate"] = i.feed.getBitrate() ? i.feed.getBitrate().split(" ")[0] + " Kbps" : 0;
          }
          if (item.googActiveConnection == "true" || item.type == "ice") {  //获取ICE
            elm["ice"]["sTransportType"] = item.googTransportType
            elm["ice"]["nRtt"] = parseInt(item.googRtt)
            elm["ice"]["nBytesReceived"] = parseInt(item.bytesReceived)
            elm["ice"]["nBytesSent"] = parseInt(item.bytesSent)
            elm["ice"]["nPacketsSent"] = parseInt(item.bytesSent)
            elm["ice"]["nSendPacketsDiscarded"] = parseInt(item.packetsDiscardedOnSend)
            elm["ice"]["sLocalCandidateId"] = item.localCandidateId || (item.localData ? item.localData.id : "")
            elm["ice"]["sLocalAddress"] = item.googLocalAddress || (item.localData ? item.localData.address : "")
            elm["ice"]["sRemoteCandidateId"] = item.remoteCandidateId || (item.remoteData ? item.remoteData.id : "")
            elm["ice"]["sRemoteAddress"] = item.googRemoteAddress || (item.localData ? item.remoteData.address : "")
            basic_data[i.base.user_id]["rtt"] = item.googRtt
          }
        });

        if (!elm.is_pub) {
          elm.userdata.nStreamId = i.base.stream_id;
          elm.userdata.sStreamTag = i.base.stream_tag;
          elm.userdata.nUserId = i.base.user_id.split("vsnb")[0];

        } else {

          elm.userdata.nStreamId = i.base.stream_id
          elm.userdata.sStreamTag = i.base.stream_tag
          elm.userdata.nUserId = i.base.user_id.split("vsnb")[0];

          elm.userdata.turnServer = this.enable_turn ? this.turn_info : "-"

        }

        let nick_name = this.getMembers().filter((v) => {
          return v.userId == i.base.user_id
        })

        if (nick_name.length > 0) {
          elm.userdata.nNickName = nick_name[0].userName
        }

        meta.args = elm


        if (elm.is_pub) {
          if (elm["vo"] && elm["vo"]["nBytesSent"]) {
            this.avg_output_bitrate_current = elm["vo"]["nBytesSent"] || elm["ao"]["nBytesSent"]
            var dealta_data = this.avg_output_bitrate_current - this.avg_output_bitrate
            if (dealta_data > 0) {
              this.avg_bitrate_rs["upload_bit"] = parseInt(dealta_data / 1024 * 8 / 10) + " Kbps"
              // elm["ab"] = parseInt(this.avg_bitrate_rs["upload_bit"].split("Kbps")[0]*1000)
              elm["ab"] = elm.bwe.iActualEncBitrate
            }
            this.avg_output_bitrate = this.avg_output_bitrate_current
          }


        } else {
          elm["ab"] = i.feed.getBitrate() ? parseInt(i.feed.getBitrate().split(" ")[0]) * 1024 : 0
        }

        if (this._stats_server) {
          fetch.POST(`${this._stats_server}/vsstats`, meta, {"Content-Type": "application/json"})
        }

      }

      var total_download_bitrate = 0
      var count = 0
      for (let bd in basic_data) {
        if (bd != this._user_id && basic_data[bd].downloadBitrate && basic_data[bd].downloadBitrate.split(" ").length > 1) {
          total_download_bitrate += parseInt(basic_data[bd].downloadBitrate.split(" ")[0])
          count++
        }
      }

      if (count > 0) {
        this.avg_bitrate_rs["download_bit"] = parseInt((total_download_bitrate)) + "Kbps"

      }
      if (basic_data[this._user_id]) {
        basic_data[this._user_id]["upload_bit"] = this.avg_bitrate_rs["upload_bit"]
        basic_data[this._user_id]["download_bit"] = this.avg_bitrate_rs["download_bit"]
      }


      this._listenerManager._emitListener(ListenerManager.COMMON_LISTENER.COMMON_ON_QUALITY_NOTIFY, ListenerManager.COMMON_MESSAGE.QUALITYS, "", basic_data)

      this._quality_data.splice(0)
    }, 10000)
  }

  startReportEvent(lei, r, m, other_info) {
    // var info = {"browser": "unknown", "version": "unknown"}
    // let self = this
    // if (window.vs_sdk_broswer) {
    //   info = new Browser();
    //
    // }
    // let meta = {
    //   "appid": this._application_id || 'unknown',
    //   "ui": this._user_id.split("vsnb")[0],
    //   "v": this._dataversion,
    //   "tm": new Date().getTime(),
    //   "lei": lei,
    //   "ei": this._event_id,
    //   "ai": 0,
    //   "ti": this._tenant_id,
    //   "m": "client",
    //   "sn": info.browser,
    //   "sv": info.version || "unknown",
    //   "deb": "",
    //   "den": "h5",
    //   "b": "",
    //   "bv": "",
    //   "udid": this._user_id.split("vsnb")[0],
    //   "sdkv": this._sdkv,
    //   "ip": this._client_ip || "",
    //   "cput": null,
    //   "args": {}
    // }
    // meta.args.r = r || ""
    // meta.args.m = m || ""
    // meta.args.others = other_info
    // if (this._stats_server) {
    //
    //   fetch.POST(`${this._stats_server}/vsevents`, meta, {"Content-Type": "application/json"})
    // }

  }


  static Tools_AttachLocalMedia(dom, stream) {
    try {
      dom.srcObject = stream;
      dom.muted = "muted";
    } catch (ex) {

    }

  }

  attachRemoteMedia(dom, stream) {
    try {
      dom.srcObject = stream;
    } catch (ex) {

    }

  }

  detachMedia(fromDom) {
    try {
      fromDom.src = "";

    } catch (ex) {

    }
  }

  _closePage() {
    if (navigator.userAgent.indexOf("MSIE") > 0) {
      if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
        window.opener = null;
        window.close();
      } else {
        window.open('', '_top');
        window.top.close();
      }
    } else if (navigator.userAgent.indexOf("Firefox") > 0) {
      window.location.href = 'about:blank'; //火狐默认状态非window.open的页面window.close是无效的
      //window.history.go(-2);
    } else {
      window.opener = null;
      window.open('', '_self', '');
      window.close();
    }
  }

}

function checkIfLegacyData(data) {
  let legacyData = false
  data.forEach(function (s) {
    if (['outbound-rtp', 'remote-outbound-rtp', 'inbound-rtp',
      'remote-inbound-rtp'].includes(s.type)) {
      legacyData = true
    }
  })

  return legacyData
}

function mergeLegacyData(data) {
  let final = []
  let audiossrc = {}
  let videossrc = {}
  let VideoBwe = {}
  let ice = {}
  data.forEach(function (s) {
    if (['outbound-rtp', 'remote-outbound-rtp', 'inbound-rtp',
      'remote-inbound-rtp', 'media-source', 'codec'].includes(s.type)) {
      if (s.mediaType == "audio" || s.kind == "audio") {
        audiossrc = {...audiossrc, ...s}

      } else if (s.mediaType == "video" || s.kind == "video") {
        videossrc = {...videossrc, ...s}
        if (s.type == "outbound-rtp" && s.targetBitrate) {
          VideoBwe["targetBitrate"] = s.targetBitrate

        }
      }

      if (s.mimeType) {
        if (s.mimeType.indexOf("video") >= 0) {
          videossrc["mimeType"] = s.mimeType.split("/")[1]
        } else {
          audiossrc["mimeType"] = s.mimeType.split("/")[1]
        }
      }
    }

    if (['candidate-pair'].includes(s.type)) {
      VideoBwe["googTargetEncBitrate"] = s.availableOutgoingBitrate
      ice["packetsDiscardedOnSend"] = s.bytesDiscardedOnSend
    }
    if (['local-candidate'].includes(s.type)) {
      ice["localData"] = s
    }
    if (['remote-candidate'].includes(s.type)) {
      ice["remoteData"] = s
    }

    if (['transport'].includes(s.type)) {
      ice = {...ice, ...s}
    }
  })
  audiossrc["type"] = "ssrc"
  videossrc["type"] = "ssrc"
  VideoBwe["type"] = "VideoBwe"
  ice["type"] = "ice"
  final.push(audiossrc)
  final.push(videossrc)
  final.push(VideoBwe)
  final.push(ice)
  return final

}

window.TRTCEngine = TRTCEngine;
window.VSRTCEngine = TRTCEngine;

